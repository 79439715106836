.lc-dt,
.content-highlight {
  position: relative;
  padding-left: $spacer;
  list-style: none;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: $secondary;
    border-radius: 2px;
  }
}
