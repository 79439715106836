@import "node_modules/bootstrap/scss/tables";

//
// Basic Bootstrap table
//
.table {
  thead {
    th {
      border-top: none;
    }
  }

  tbody + tbody {
    border-top-width: 1px;
  }
  .sticky-column {
    position: sticky;
    left: 0;
    box-shadow: inset -1px 0 1px $border-color;
  }

  a + a {
    margin-left: 0.5rem;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-light-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-light-accent-bg;
  }
}

//
// Table Sticky Header
//
.table-sticky-header {
  max-height: 18.4rem;
  overflow-y: auto;
  margin-bottom: $spacer;

  table {
    margin-bottom: 0;
  }

  thead {
    th {
      @extend .sticky-top;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
      }

      &:before {
        bottom: -2px;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
      }
    }
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping
.table-hover {
  tbody tr {
    @include hover {
      background-color: inherit;
      transform: scale(1);
      @extend .shadow;
    }
  }
  &.table-striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      @include hover {
        background-color: $table-accent-bg;
        transform: scale(1);
        @extend .shadow;
      }
    }
  }
  &.table-light-striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      @include hover {
        color: $table-hover-color;
        background-color: $table-light-accent-bg;
        transform: scale(1);
        @extend .shadow;
      }
    }
  }
}
