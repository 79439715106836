@import "node_modules/bootstrap/scss/alert";

.alert {
  color: $body-color;
  display: table;

  @each $color, $value in $theme-colors {
    &.alert-#{$color} {
      background-color: $white;

      .alert-icon {
        background-color: $value;
        font-size: ($spacer * 1.5);
        color: $white;
        top: initial;
      }
    }
  }

  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  &.alert-dismissible {
    padding-right: 0;
    .close {
      padding: $alert-content-padding;
      position: initial;
      font-size: ($font-size-base * 1.25);
      .material-icons-round {
        top: initial;
      }
    }
  }

  .alert-icon,
  .text,
  .close,
  .action {
    display: table-cell;
    vertical-align: middle;
  }

  .alert-icon {
    padding: ($spacer * 0.5) ($spacer * 0.75);
    border-radius: 7px 0 0 7px;
  }

  .text {
    width: 100%;
    padding: $alert-content-padding;
  }

  .action {
    color: $link-color;
    padding: $alert-content-padding;
    white-space: nowrap;
    .material-icons-round {
      font-size: 1.4rem;
    }
  }
}
