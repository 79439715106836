@import "node_modules/bootstrap/scss/dropdown";

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-header {
  @extend .mt-0, .mb-0;
}

.custom-dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }

    .material-icons-round {
      margin-right: -$spacer * 0.25;
      margin-left: -$spacer * 0.25;
    }
  }
}
