.sidebar {
  background-color: $sidebar-bg-color;
  width: $sidebar-width;
  border-right: 1px solid $sidebar-border-color;
  height: 100%;

  .nav {
    padding: ($spacer * 1.5) 0;
    margin: 0;
  }

  .nav-link {
    color: $sidebar-item-color;
    padding: ($spacer * 0.75) ($spacer * 1);
    border-left: ($spacer * 0.25) solid transparent;

    .material-icons-round,
    .material-icons {
      margin-top: 0;
    }

    &:hover,
    &.active,
    &:focus {
      font-weight: $font-weight-bold;
      .icon {
        color: $sidebar-item-hover-icon-color;
      }
    }

    &:hover {
      background-color: $sidebar-item-hover-bg;
      color: $sidebar-item-hover-color;
      border-color: $sidebar-item-border-color;
    }

    &.active,
    &:focus {
      background-color: $sidebar-item-active-bg;
      color: $sidebar-item-active-color;
      border-color: $sidebar-item-border-color;
    }
  }

  .sidebar-subnav {
    background-color: $sidebar-subnav-bg;
    margin: 0;
    padding: 0;

    .nav-link {
      border: none;
      padding: ($spacer * 0.5) ($spacer * 1.5) ($spacer * 0.5) ($spacer * 3.75);

      &:hover,
      &.active,
      &:focus {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
}

.sidebar.sidebar-dark {
  background-color: $sidebar-dark-bg-color;
  border-right: 1px solid $sidebar-dark-border-color;

  .nav-link {
    color: $sidebar-dark-item-color;

    &:hover,
    &.active,
    &:focus {
      .icon {
        color: $sidebar-dark-item-hover-icon-color;
      }
    }

    &:hover {
      background-color: $sidebar-dark-item-hover-bg;
      color: $sidebar-dark-item-hover-color;
      border-color: $sidebar-dark-item-border-color;
    }

    &.active,
    &:focus {
      background-color: $sidebar-dark-item-active-bg;
      color: $sidebar-dark-item-active-color;
      border-color: $sidebar-dark-item-border-color;
    }
  }
  .sidebar-subnav {
    background-color: $sidebar-dark-subnav-bg;
  }
}
