@import "node_modules/bootstrap/scss/custom-forms";

.custom-file {
  .custom-file-input {
    &:disabled + .custom-file-label {
      color: $input-disabled-color;

      &::after {
        color: $input-disabled-color;
      }
    }
  }
}

.custom-select {
  &:disabled {
    background: $custom-select-disabled-bg $custom-select-background-disabled;
  }
  &.custom-select-sm {
    padding-right: ($spacer * 2);
    background: $custom-select-bg $custom-sm-select-background;
    &:disabled {
      background: $custom-select-disabled-bg $custom-sm-select-background-disabled;
    }
  }
}

.custom-control-input {
  &:focus:not(:focus-visible) ~ .custom-control-label::before {
    outline: $input-btn-focus-width solid transparent !important;
    box-shadow: none !important;
  }
  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: initial;
  }
}

.custom-control-label {
  // Foreground (icon)
  &::after {
    background: no-repeat;
  }
}

.custom-radio {
  .custom-control-label::before {
    top: 0.1rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  .custom-control-label::after {
    top: 0.1rem;
  }
}

.custom-switch {
  .custom-control-input {
    &.is-invalid {
      ~ .custom-control-label {
        &::after {
          background-color: $danger;
        }
      }

      &:checked {
        ~ .custom-control-label {
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }
}
