@import "node_modules/bootstrap/scss/tooltip";

.tooltip {
  .arrow {
    &::before {
    }
    &::after {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}
.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $border-color;
    }
    &::after {
      top: -1px;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}
.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: $border-color;
    }
    &::after {
      right: -1px;
      border-width: ($tooltip-arrow-width * 0.5) $tooltip-arrow-height
        ($tooltip-arrow-width * 0.5) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $border-color;
    }
    &::after {
      bottom: -1px;
      border-width: 0 ($tooltip-arrow-width * 0.5) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: $border-color;
    }
    &::after {
      left: -1px;
      border-width: ($tooltip-arrow-width * 0.5) 0 ($tooltip-arrow-width * 0.5)
        $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.tooltip-inner {
  border: $tooltip-border;
  @extend .shadow;
}
