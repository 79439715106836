.footer-logo {
  @extend .img-fluid;
  height: $spacer * 3;
}

.lc-footer-accordion {
  padding: 0;
  list-style: none;

  & > li {
    border-top: 1px solid rgba($white, 0.5);

    &:last-child {
      border-bottom: 1px solid rgba($white, 0.5);
    }

    & > a {
      display: block;
      color: $white;
      text-decoration: none;
      padding: 8px 16px;

      &:hover,
      &.active {
        background: rgba($white, 0.17);
      }
      .material-icons,
      .material-icons-round {
        margin-top: 0;
      }
    }

    .content {
      margin: 0;
      padding: 12px 16px;
      list-style: none;
      border-top: 1px solid rgba($white, 0.5);
      background: rgba($black, 0.3);

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $white;
        }
      }
    }
  }
}
