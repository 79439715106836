@import "node_modules/bootstrap/scss/forms";

.form-control {
  &:disabled,
  &[readonly] {
    color: $input-disabled-color;
  }
}

select {
  &:disabled {
    opacity: 1;
  }
}

label {
  font-weight: $font-weight-semibold;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  font-weight: $font-weight-normal;
}

.form-group {
  &.prepend-icon,
  &.append-icon {
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      z-index: 2;
      display: block;
      width: calc(3.5 * #{$input-btn-padding-x});
      height: $input-height;
      line-height: $input-height;
      text-align: center;
      pointer-events: none;
    }

    .form-control {
      &:disabled + .icon {
        color: $input-disabled-color;
      }
    }
  }

  &.prepend-icon {
    .form-control {
      padding-left: $input-height;
      border-top-left-radius: $input-border-radius !important;
      border-bottom-left-radius: $input-border-radius !important;
    }

    .icon {
      left: 0;
      z-index: 4;
    }
  }

  &.append-icon {
    .form-control {
      padding-right: $input-height;
      border-top-right-radius: $input-border-radius !important;
      border-bottom-right-radius: $input-border-radius !important;
    }

    .icon {
      right: 0;
      z-index: 4;
    }
  }
}
