@import "node_modules/bootstrap/scss/badge";

.badge {
  &.badge-pill {
    &.status {
      width: $spacer * 5;
    }
    &.badge-outline {
      background-color: transparent;
      color: $body-color;
      padding-top: calc(#{$badge-padding-y} - 1px);
      padding-bottom: calc(#{$badge-padding-y} - 1px);
    }
  }
}
