@import 'node_modules/labcorp-bootstrap/scss/labcorp/required';

/**
 * Be judicious when adding global styles here. 99% of styling should be done
 * within a specific component's stylesheet. In the **VERY** rare event that
 * you need to add global styles here, be sure to scope your styling in such a
 * way that will not inadvertently pollute the global space.
 *
 * For example, you can add your styles inside of a css class selector rather
 * than broadly selecting html elements:
 *
 * ```scss
 * // This is very, very bad
 * button {
 *   background-color: #fff;
 * }
 *
 * // Do this instead
 * .my-css-class-selector {
 *   & button {
 *     background-color: #fff;
 *   }
 * }
 * ```
 */

// Layout Styles
.main-content-wrapper {
  > router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

// Custom Buttons Styles
.btn.btn-text-lg {
  font-size: 1.2rem !important;
}

.btn.btn-link.card-link.small {
  font-size: 14px;
}

.ngb-dp-day.disabled {
  .custom-day {
    color: #b0b8be !important;
  }
}

// Invalid select box border
ui-select.is-invalid .ng-select .ng-select-container {
  border: 1px solid #dc1439 !important;
}

// NG Select Disabled Styles
@import 'node_modules/@ng-select/ng-select/scss/mixins';

ui-select > .ng-select.ng-select-disabled > .ng-select-container {
  color: $input-disabled-color;
  background-color: $input-disabled-bg !important;
}

// Custom Accordion Styles
.accordion.custom-accordion {
  .card {
    .card-header {
      padding: 0;
    }
  }
}

.accordion {
  > .card {
    &.active {
      overflow: visible;
    }
  }

  > .card:first-of-type {
    &.active {
      > .card-header {
        border-top-left-radius: $card-inner-border-radius;
        border-top-right-radius: $card-inner-border-radius;
      }
    }
  }

  > .card:last-of-type {
    &.active {
      .card-body {
        border-bottom-left-radius: $card-inner-border-radius;
        border-bottom-right-radius: $card-inner-border-radius;
      }
    }
  }
}

ngb-datepicker .ngb-dp-day.hidden {
  background: none;
}

ngb-tooltip-window {
  left: 7px !important;
}
