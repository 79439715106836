@import "node_modules/bootstrap/scss/modal";

.modal-title {
  margin: 0;
  &.text-center {
    padding-left: 1rem;
    padding-right: 1rem;
    & ~ .close {
      margin-left: calc(-#{$modal-header-padding-x}/2);
    }
  }
}

.modal-footer {
  border: none;
  padding-top: 0;
}

.modal {
  .close {
    opacity: 0.5;
    outline: none;
    padding: .75rem;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      opacity: 1;
    }
  }
}
