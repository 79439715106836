@import "../../../../../node_modules/material-icons/iconfont/filled.scss";

.material-icons {
  // Position Fix
  margin-top: -0.125rem;
  vertical-align: middle;
  position: relative;

  // Icon sizes
  &.icon-sm {
    font-size: 18px;
    transform: rotate(0.03deg);
  }

  &.icon-lg {
    font-size: 29px;
  }
}

.btn {
  .material-icons-round {
    margin-top: -0.253rem;
  }
}
