// stylelint-disable declaration-no-important

@each $color, $value in $utility-colors {
    @include bg-variant(".bg-#{$color}", $value, true);
  }
  
  @if $enable-gradients {
    @each $color, $value in $utility-colors {
      @include bg-gradient-variant(".bg-gradient-#{$color}", $value, true);
    }
  }
  
  .bg-white {
    background-color: $white !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  