@import "node_modules/bootstrap/scss/pagination";

.pagination {
  border-radius: none;

  .page-item {
    &.disabled {
      .page-link {
        background-color: transparent;
        color: $body-color;
        opacity: 0.65;
      }
    }
  }

  .page-link {
    border-radius: $btn-border-radius-lg;

    &:hover {
      text-decoration: underline;
    }
  }
}
