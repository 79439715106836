.gradient-primary {
  @include gradient-x(
    $start-color: adjust-color($primary, $lightness: 17%, $saturation: -20%),
    $end-color: adjust-color($primary, $lightness: 5%, $saturation: -18%),
    $start-percent: 0%,
    $end-percent: 100%
  );
}

.gradient-secondary {
  @include gradient-x(
    $start-color: $gray-200,
    $end-color: $gray-400,
    $start-percent: 0%,
    $end-percent: 100%
  );
}
