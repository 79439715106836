@import "node_modules/bootstrap/scss/toasts";

.toast {
  border-left-width: $toast-border-left-width;
  font-size: $font-size-base;

  .toast-body {
    display: table;

    .toast-icon,
    .toast-text,
    .close {
      display: table-cell;
      vertical-align: middle;
    }

    .toast-icon {
      padding-right: $spacer * 0.75;
    }
    .toast-text {
      width: 100%;
    }
    .close {
      padding: ($spacer * 0.75);
      margin-top: -($spacer * 0.75);
      margin-right: -($spacer * 0.75);
      margin-bottom: -($spacer * 0.75);
    }
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the toast.

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    @include toast-variant(
      theme-color-level($color, $toast-bg-level),
      theme-color-level($color, $toast-border-level),
      theme-color-level($color, $toast-icon-color-level)
    );
  }
}
