@import "node_modules/bootstrap/scss/nav";

.nav {
  &.sub-nav {
    margin: 0;
    border-bottom: 1px solid $gray-500;

    .nav-item {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .nav-link {
      color: $azure;
      position: relative;

      &::before {
        content: attr(data-label);
        font-weight: bold;
        visibility: hidden;
      }

      @include hover-focus {
        font-weight: 700;
      }

      &.active {
        font-weight: 700;

        &::after {
          content: "";
          position: absolute;
          bottom: -0.125rem;
          left: 0;
          width: 100%;
          border-radius: $border-radius;
          display: block;
          height: 0.25rem;
          background-color: $turquoise-darker;
        }
      }

      &.disabled {
        color: $nav-link-disabled-color;
      }
    }

    .nav-link-label {
      text-align: center;
      position: absolute;
      top: $nav-link-padding-y;
      bottom: $nav-link-padding-y;
      right: $nav-link-padding-x;
      left: $nav-link-padding-x;
    }
  }
}
