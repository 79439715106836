@charset "UTF-8";
@import "~@fontsource/source-sans-pro/latin.css";
@import "~@fontsource/source-sans-pro/latin-200-italic.css";
@import "~@fontsource/source-sans-pro/latin-300-italic.css";
@import "~@fontsource/source-sans-pro/latin-400-italic.css";
@import "~@fontsource/source-sans-pro/latin-600-italic.css";
@import "~@fontsource/source-sans-pro/latin-700-italic.css";
@import "~@fontsource/source-sans-pro/latin-900-italic.css";
:root {
  --blue: #3A5CE9;
  --indigo: #56268A;
  --purple: #6C2FAC;
  --pink: #F7758C;
  --red: #DC1439;
  --orange: #D08400;
  --yellow: #FFA400;
  --green: #069D06;
  --teal: #B4F6F5;
  --cyan: #4CD5F7;
  --white: #FFFFFF;
  --gray: #918F8F;
  --gray-dark: #393536;
  --primary: #3A5CE9;
  --secondary: #2998E3;
  --success: #069D06;
  --info: #6C2FAC;
  --warning: #FFA400;
  --danger: #DC1439;
  --light: #E9E8E8;
  --dark: #393536;
  --success-dark: #058A05;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1232px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: "Source Sans Pro", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231F20;
  text-align: left;
  background-color: #FFFFFF;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3A5CE9;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1637c1;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #706D6E;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.3;
}

h1, .h1 {
  font-size: 2.25rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.35rem + 1.2vw);
  }
}

h2, .h2 {
  font-size: 1.875rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.3125rem + 0.75vw);
  }
}

h3, .h3 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.454546;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.454546;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-3 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.454546;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

.display-4 {
  font-size: 2.75rem;
  font-weight: 300;
  line-height: 1.454546;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.4rem + 1.8vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #BDBBBC;
}

small,
.small {
  font-size: 0.875rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #7B7979;
}
.blockquote-footer::before {
  content: "— ";
}

h1,
.h1,
h3,
.h3,
h5,
.h5 {
  line-height: 1.3333333;
}

h2,
.h2 {
  line-height: 1.2;
}

h4,
.h4 {
  line-height: 1.4;
}

h1,
.h1,
h2,
.h2,
h4,
.h4 {
  font-weight: 600;
}

h3,
.h3,
h5,
.h5 {
  font-weight: 400;
}

h3,
.h3 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  h3,
.h3 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h6,
.h6 {
  line-height: 1.5;
  font-weight: 700;
}

.lead {
  line-height: 1.3;
}

b,
strong {
  font-weight: 700;
}

small,
.small {
  line-height: 1.3;
}

.xsmall {
  font-size: 0.75rem;
  line-height: 1.3;
}

.img-fluid, .footer-logo {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #BDBBBC;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7B7979;
}

code {
  font-size: 87.5%;
  color: #B7112F;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #393536;
  border-radius: 0.5rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #393536;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1232px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1232px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #231F20;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #918F8F;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #918F8F;
}
.table tbody + tbody {
  border-top: 2px solid #918F8F;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #918F8F;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #918F8F;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(35, 31, 32, 0.05);
}

.table-hover tbody tr:hover {
  color: #231F20;
  background-color: rgba(35, 31, 32, 0.1);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ebeffd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b0bef6;
}

.table-hover .table-primary:hover {
  transform: scale(1);
  background-color: #ebeffd;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ebeffd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #eaf5fc;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a9d6f4;
}

.table-hover .table-secondary:hover {
  transform: scale(1);
  background-color: #eaf5fc;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #eaf5fc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e6f5e6;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9bd89b;
}

.table-hover .table-success:hover {
  transform: scale(1);
  background-color: #e6f5e6;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #e6f5e6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #f0eaf7;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c4acde;
}

.table-hover .table-info:hover {
  transform: scale(1);
  background-color: #f0eaf7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #f0eaf7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff6e6;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdb99;
}

.table-hover .table-warning:hover {
  transform: scale(1);
  background-color: #fff6e6;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff6e6;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fce8eb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f1a1b0;
}

.table-hover .table-danger:hover {
  transform: scale(1);
  background-color: #fce8eb;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fce8eb;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f6f6;
}

.table-hover .table-light:hover {
  transform: scale(1);
  background-color: #fdfdfd;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #fdfdfd;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ebebeb;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #b0aeaf;
}

.table-hover .table-dark:hover {
  transform: scale(1);
  background-color: #ebebeb;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #ebebeb;
}

.table-success-dark,
.table-success-dark > th,
.table-success-dark > td {
  background-color: #e6f3e6;
}
.table-success-dark th,
.table-success-dark td,
.table-success-dark thead th,
.table-success-dark tbody + tbody {
  border-color: #9bd09b;
}

.table-hover .table-success-dark:hover {
  transform: scale(1);
  background-color: #e6f3e6;
}
.table-hover .table-success-dark:hover > td,
.table-hover .table-success-dark:hover > th {
  background-color: #e6f3e6;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #DDF7FD;
}

.table-hover .table-active:hover {
  transform: scale(1);
  background-color: #DDF7FD;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #DDF7FD;
}

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #4F4C4D;
  border-color: #625f60;
}
.table .thead-light th {
  color: #656262;
  background-color: #D3D2D2;
  border-color: #918F8F;
}

.table-dark {
  color: #FFFFFF;
  background-color: #4F4C4D;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #625f60;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1231.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.table thead th {
  border-top: none;
}
.table tbody + tbody {
  border-top-width: 1px;
}
.table .sticky-column {
  position: sticky;
  left: 0;
  box-shadow: inset -1px 0 1px #918F8F;
}
.table a + a {
  margin-left: 0.5rem;
}

.table-light-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.5);
}

.table-sticky-header {
  max-height: 18.4rem;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.table-sticky-header table {
  margin-bottom: 0;
}
.table-sticky-header thead th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}
.table-sticky-header thead th:before {
  bottom: -2px;
  border-bottom: 2px solid #918F8F;
}

.table-hover tbody tr:hover {
  background-color: inherit;
  transform: scale(1);
}
.table-hover.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: rgba(35, 31, 32, 0.05);
  transform: scale(1);
}
.table-hover.table-light-striped tbody tr:nth-of-type(odd):hover {
  color: #231F20;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(1);
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.375rem + 2px);
  padding: 0.6875rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231F20;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #231F20;
  background-color: #FFFFFF;
  border-color: #adbbf6;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.form-control::placeholder {
  color: #706D6E;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #E9E8E8;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #231F20;
}
select.form-control:focus::-ms-value {
  color: #231F20;
  background-color: #FFFFFF;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.875rem;
  line-height: 1.3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #231F20;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.3em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 0.5rem;
}

.form-control-lg {
  height: calc(1.5em + 2rem + 2px);
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #706D6E;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #069D06;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #069d06;
  border-radius: 0.75rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #069D06;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #069D06;
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #069D06;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #069D06;
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #069D06;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #069D06;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #069D06;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #08ce08;
  background-color: #08ce08;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #069D06;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #069D06;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #069D06;
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #DC1439;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #dc1439;
  border-radius: 0.75rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #DC1439;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #DC1439;
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #DC1439;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #DC1439;
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DC1439;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC1439;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #DC1439;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ed3658;
  background-color: #ed3658;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #DC1439;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC1439;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DC1439;
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form-control:disabled, .form-control[readonly] {
  color: #5A5758;
}

select:disabled {
  opacity: 1;
}

label {
  font-weight: 600;
}

input[type=checkbox] + label,
input[type=radio] + label {
  font-weight: 400;
}

.form-group.prepend-icon, .form-group.append-icon {
  position: relative;
}
.form-group.prepend-icon .icon, .form-group.append-icon .icon {
  position: absolute;
  top: 0;
  z-index: 2;
  display: block;
  width: calc(3.5 * 1rem);
  height: calc(1.5em + 1.375rem + 2px);
  line-height: calc(1.5em + 1.375rem + 2px);
  text-align: center;
  pointer-events: none;
}
.form-group.prepend-icon .form-control:disabled + .icon, .form-group.append-icon .form-control:disabled + .icon {
  color: #5A5758;
}
.form-group.prepend-icon .form-control {
  padding-left: calc(1.5em + 1.375rem + 2px);
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}
.form-group.prepend-icon .icon {
  left: 0;
  z-index: 4;
}
.form-group.append-icon .form-control {
  padding-right: calc(1.5em + 1.375rem + 2px);
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}
.form-group.append-icon .icon {
  right: 0;
  z-index: 4;
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #231F20;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6875rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #231F20;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.btn.disabled, .btn:disabled {
  opacity: 0.7;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-primary:hover {
  color: #FFFFFF;
  background-color: #183dd8;
  border-color: #183dd8;
}
.btn-primary:focus, .btn-primary.focus {
  color: #FFFFFF;
  background-color: #183dd8;
  border-color: #183dd8;
  box-shadow: 0 0 0 0.125rem rgba(88, 116, 236, 0.75);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #183dd8;
  border-color: #183dd8;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(88, 116, 236, 0.75);
}

.btn-secondary {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-secondary:hover {
  color: #FFFFFF;
  background-color: #197dc0;
  border-color: #197dc0;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #FFFFFF;
  background-color: #197dc0;
  border-color: #197dc0;
  box-shadow: 0 0 0 0.125rem rgba(73, 167, 231, 0.75);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #197dc0;
  border-color: #197dc0;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(73, 167, 231, 0.75);
}

.btn-success {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-success:hover {
  color: #FFFFFF;
  background-color: #046c04;
  border-color: #046c04;
}
.btn-success:focus, .btn-success.focus {
  color: #FFFFFF;
  background-color: #046c04;
  border-color: #046c04;
  box-shadow: 0 0 0 0.125rem rgba(43, 172, 43, 0.75);
}
.btn-success.disabled, .btn-success:disabled {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #046c04;
  border-color: #046c04;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(43, 172, 43, 0.75);
}

.btn-info {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-info:hover {
  color: #FFFFFF;
  background-color: #532484;
  border-color: #532484;
}
.btn-info:focus, .btn-info.focus {
  color: #FFFFFF;
  background-color: #532484;
  border-color: #532484;
  box-shadow: 0 0 0 0.125rem rgba(130, 78, 184, 0.75);
}
.btn-info.disabled, .btn-info:disabled {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #532484;
  border-color: #532484;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(130, 78, 184, 0.75);
}

.btn-warning {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-warning:hover {
  color: #FFFFFF;
  background-color: #cc8300;
  border-color: #cc8300;
}
.btn-warning:focus, .btn-warning.focus {
  color: #FFFFFF;
  background-color: #cc8300;
  border-color: #cc8300;
  box-shadow: 0 0 0 0.125rem rgba(225, 147, 8, 0.75);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #FFFFFF;
  background-color: #cc8300;
  border-color: #cc8300;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(225, 147, 8, 0.75);
}

.btn-danger {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-danger:hover {
  color: #FFFFFF;
  background-color: #ad102d;
  border-color: #ad102d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #FFFFFF;
  background-color: #ad102d;
  border-color: #ad102d;
  box-shadow: 0 0 0 0.125rem rgba(225, 55, 87, 0.75);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ad102d;
  border-color: #ad102d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(225, 55, 87, 0.75);
}

.btn-light {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-light:hover {
  color: #393536;
  background-color: #d0cece;
  border-color: #d0cece;
}
.btn-light:focus, .btn-light.focus {
  color: #393536;
  background-color: #d0cece;
  border-color: #d0cece;
  box-shadow: 0 0 0 0.125rem rgba(207, 205, 205, 0.75);
}
.btn-light.disabled, .btn-light:disabled {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #393536;
  background-color: #d0cece;
  border-color: #d0cece;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(207, 205, 205, 0.75);
}

.btn-dark {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-dark:hover {
  color: #FFFFFF;
  background-color: #1f1c1d;
  border-color: #1f1c1d;
}
.btn-dark:focus, .btn-dark.focus {
  color: #FFFFFF;
  background-color: #1f1c1d;
  border-color: #1f1c1d;
  box-shadow: 0 0 0 0.125rem rgba(87, 83, 84, 0.75);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1f1c1d;
  border-color: #1f1c1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(87, 83, 84, 0.75);
}

.btn-success-dark {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-success-dark:hover {
  color: #FFFFFF;
  background-color: #035903;
  border-color: #035903;
}
.btn-success-dark:focus, .btn-success-dark.focus {
  color: #FFFFFF;
  background-color: #035903;
  border-color: #035903;
  box-shadow: 0 0 0 0.125rem rgba(43, 156, 43, 0.75);
}
.btn-success-dark.disabled, .btn-success-dark:disabled {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-success-dark:not(:disabled):not(.disabled):active, .btn-success-dark:not(:disabled):not(.disabled).active, .show > .btn-success-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #035903;
  border-color: #035903;
}
.btn-success-dark:not(:disabled):not(.disabled):active:focus, .btn-success-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-success-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(43, 156, 43, 0.75);
}

.btn-outline-primary {
  color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:hover {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3A5CE9;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}

.btn-outline-secondary {
  color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:hover {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.125rem rgba(41, 152, 227, 0.75);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2998E3;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(41, 152, 227, 0.75);
}

.btn-outline-success {
  color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.75);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #069D06;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.75);
}

.btn-outline-info {
  color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:hover {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.125rem rgba(108, 47, 172, 0.75);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #6C2FAC;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(108, 47, 172, 0.75);
}

.btn-outline-warning {
  color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:hover {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.125rem rgba(255, 164, 0, 0.75);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFA400;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(255, 164, 0, 0.75);
}

.btn-outline-danger {
  color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.75);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #DC1439;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.75);
}

.btn-outline-light {
  color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:hover {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.125rem rgba(233, 232, 232, 0.75);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #E9E8E8;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(233, 232, 232, 0.75);
}

.btn-outline-dark {
  color: #393536;
  border-color: #393536;
}
.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.125rem rgba(57, 53, 54, 0.75);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #393536;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(57, 53, 54, 0.75);
}

.btn-outline-success-dark {
  color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:hover {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:focus, .btn-outline-success-dark.focus {
  box-shadow: 0 0 0 0.125rem rgba(5, 138, 5, 0.75);
}
.btn-outline-success-dark.disabled, .btn-outline-success-dark:disabled {
  color: #058A05;
  background-color: transparent;
}
.btn-outline-success-dark:not(:disabled):not(.disabled):active, .btn-outline-success-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-success-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-success-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(5, 138, 5, 0.75);
}

.btn-link {
  font-weight: 400;
  color: #3A5CE9;
  text-decoration: none;
}
.btn-link:hover {
  color: #1637c1;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #7B7979;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 1rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn-lg, .btn-group-lg > .btn {
  font-weight: 600;
}

.btn-link {
  font-weight: 700;
}

.btn-min-width {
  min-width: 8rem;
}

.btn:focus:not(:focus-visible) {
  outline: 0.125rem solid transparent !important;
  box-shadow: none !important;
}
.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary.active, .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus {
  background: #1A2188;
}
.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  background: #3A5CE9;
}
.btn.btn-secondary {
  background: #FFFFFF;
  color: #3A5CE9;
  border-color: #3A5CE9;
  color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn.btn-secondary:hover {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn.btn-secondary:focus, .btn.btn-secondary.focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
  color: #3A5CE9;
  background-color: transparent;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .show > .btn.btn-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.btn.btn-secondary.disabled:hover, .btn.btn-secondary.disabled:focus, .btn.btn-secondary.disabled.focus, .btn.btn-secondary.disabled:active, .btn.btn-secondary.disabled.active, .btn.btn-secondary:disabled:hover, .btn.btn-secondary:disabled:focus, .btn.btn-secondary:disabled.focus, .btn.btn-secondary:disabled:active, .btn.btn-secondary:disabled.active {
  background: #FFFFFF;
  color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success.focus, .btn.btn-success:active, .btn.btn-success.active, .btn.btn-success:not(:disabled):not(.disabled):active:focus, .btn.btn-success:not(:disabled):not(.disabled).active:focus {
  background: #057E05;
}
.btn.btn-success.disabled, .btn.btn-success:disabled {
  background: #069D06;
}
.btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger.focus, .btn.btn-danger:active, .btn.btn-danger.active, .btn.btn-danger:not(:disabled):not(.disabled):active:focus, .btn.btn-danger:not(:disabled):not(.disabled).active:focus {
  background: #B7112F;
}
.btn.btn-danger.disabled, .btn.btn-danger:disabled {
  background: #DC1439;
}
.btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link.focus, .btn.btn-link:active, .btn.btn-link.active, .btn.btn-link:not(:disabled):not(.disabled):active:focus, .btn.btn-link:not(:disabled):not(.disabled).active:focus {
  color: #1A2188;
}
.btn.disabled, .btn:disabled {
  cursor: default;
}

a:hover, a:focus, a.focus, a:active, a.active {
  color: #1A2188;
}
a .text-success:hover, a .text-success:focus, a .text-success.focus, a .text-success:active, a .text-success.active {
  color: #057E05;
}
a .text-danger:hover, a .text-danger:focus, a .text-danger.focus, a .text-danger:active, a .text-danger.active {
  color: #B7112F;
}

.btn-outline-primary {
  background-color: #FFFFFF;
  color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:hover {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3A5CE9;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}

.btn-outline-secondary {
  background-color: #FFFFFF;
  color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:hover {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.125rem rgba(41, 152, 227, 0.75);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2998E3;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2998E3;
  border-color: #2998E3;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(41, 152, 227, 0.75);
}

.btn-outline-success {
  background-color: #FFFFFF;
  color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.75);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #069D06;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #069D06;
  border-color: #069D06;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.75);
}

.btn-outline-info {
  background-color: #FFFFFF;
  color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:hover {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.125rem rgba(108, 47, 172, 0.75);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #6C2FAC;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #6C2FAC;
  border-color: #6C2FAC;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(108, 47, 172, 0.75);
}

.btn-outline-warning {
  background-color: #FFFFFF;
  color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:hover {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.125rem rgba(255, 164, 0, 0.75);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFA400;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #393536;
  background-color: #FFA400;
  border-color: #FFA400;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(255, 164, 0, 0.75);
}

.btn-outline-danger {
  background-color: #FFFFFF;
  color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.75);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #DC1439;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #DC1439;
  border-color: #DC1439;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.75);
}

.btn-outline-light {
  background-color: #FFFFFF;
  color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:hover {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.125rem rgba(233, 232, 232, 0.75);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #E9E8E8;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #393536;
  background-color: #E9E8E8;
  border-color: #E9E8E8;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(233, 232, 232, 0.75);
}

.btn-outline-dark {
  background-color: #FFFFFF;
  color: #393536;
  border-color: #393536;
}
.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.125rem rgba(57, 53, 54, 0.75);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #393536;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #393536;
  border-color: #393536;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(57, 53, 54, 0.75);
}

.btn-outline-success-dark {
  background-color: #FFFFFF;
  color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:hover {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:focus, .btn-outline-success-dark.focus {
  box-shadow: 0 0 0 0.125rem rgba(5, 138, 5, 0.75);
}
.btn-outline-success-dark.disabled, .btn-outline-success-dark:disabled {
  color: #058A05;
  background-color: transparent;
}
.btn-outline-success-dark:not(:disabled):not(.disabled):active, .btn-outline-success-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-success-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #058A05;
  border-color: #058A05;
}
.btn-outline-success-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-success-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.125rem rgba(5, 138, 5, 0.75);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #231F20;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1232px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #A7A5A6;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #231F20;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #231F20;
  text-decoration: none;
  background-color: #D3D2D2;
}
.dropdown-item.active, .dropdown-item:active {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #3A5CE9;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #918F8F;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #706D6E;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #231F20;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}
.custom-dropdown .dropdown-toggle .material-icons-round {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6875rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231F20;
  text-align: center;
  white-space: nowrap;
  background-color: #D3D2D2;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.3em + 0.75rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 0.5rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 3rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.625rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.125rem;
  height: 1.3125rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #FFFFFF;
  border-color: #3A5CE9;
  background-color: #3A5CE9;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #adbbf6;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #FFFFFF;
  background-color: #dbe1fb;
  border-color: #dbe1fb;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #5A5758;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #E9E8E8;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.1875rem;
  left: -1.625rem;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  pointer-events: none;
  content: "";
  background-color: #FFFFFF;
  border: 2px solid #231F20;
}
.custom-control-label::after {
  position: absolute;
  top: 0.1875rem;
  left: -1.625rem;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='1 1 30 30' width='24' fill='%23FFFFFF'%3e%3cpath d='M0 0h24v24H0V0z' fill='none'/%3e%3cpath d='M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3A5CE9;
  background-color: #3A5CE9;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='-2.5 -2.75 12 12'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(58, 92, 233, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(58, 92, 233, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='-6.25 -6.25 15 15'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(58, 92, 233, 0.5);
}

.custom-switch {
  padding-left: 2.46875rem;
}
.custom-switch .custom-control-label::before {
  left: -2.46875rem;
  width: 1.96875rem;
  pointer-events: all;
  border-radius: 0.5625rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.1875rem + 4px);
  left: calc(-2.46875rem + 4px);
  width: calc(1.125rem - 8px);
  height: calc(1.125rem - 8px);
  background-color: #231F20;
  border-radius: 0.5625rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #FFFFFF;
  transform: translateX(0.84375rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(58, 92, 233, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.375rem + 2px);
  padding: 0.6875rem 3rem 0.6875rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231F20;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23231F20' width='18' height='18'%3e%3cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e") no-repeat right 1rem center/1.5rem;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #adbbf6;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-select:focus::-ms-value {
  color: #231F20;
  background-color: #FFFFFF;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #5A5758 !important;
  background-color: #E9E8E8;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #231F20;
}

.custom-select-sm {
  height: calc(1.3em + 0.75rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2rem + 2px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.375rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.375rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #adbbf6;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #E9E8E8;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.375rem + 2px);
  padding: 0.6875rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #231F20;
  background-color: #FFFFFF;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.375rem);
  padding: 0.6875rem 1rem;
  line-height: 1.5;
  color: #231F20;
  content: "Browse";
  background-color: #D3D2D2;
  border-left: inherit;
  border-radius: 0 0.75rem 0.75rem 0;
}

.custom-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3A5CE9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #dbe1fb;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #BDBBBC;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3A5CE9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #dbe1fb;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #BDBBBC;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  background-color: #3A5CE9;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #dbe1fb;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #BDBBBC;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #BDBBBC;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #918F8F;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #918F8F;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #918F8F;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.custom-file .custom-file-input:disabled + .custom-file-label {
  color: #5A5758;
}
.custom-file .custom-file-input:disabled + .custom-file-label::after {
  color: #5A5758;
}

.custom-select:disabled {
  background: #E9E8E8 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%235A5758' width='18' height='18'%3e%3cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e") no-repeat right 1rem center/1.5rem;
}
.custom-select.custom-select-sm {
  padding-right: 2rem;
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23231F20' width='18' height='18'%3e%3cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e") no-repeat right 0.25rem center/1.5rem;
}
.custom-select.custom-select-sm:disabled {
  background: #E9E8E8 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%235A5758' width='18' height='18'%3e%3cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e") no-repeat right 0.25rem center/1.5rem;
}

.custom-control-input:focus:not(:focus-visible) ~ .custom-control-label::before {
  outline: 0.125rem solid transparent !important;
  box-shadow: none !important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: initial;
}

.custom-control-label::after {
  background: no-repeat;
}

.custom-radio .custom-control-label::before {
  top: 0.1rem;
  width: 1.25rem;
  height: 1.25rem;
}
.custom-radio .custom-control-label::after {
  top: 0.1rem;
}

.custom-switch .custom-control-input.is-invalid ~ .custom-control-label::after {
  background-color: #DC1439;
}
.custom-switch .custom-control-input.is-invalid:checked ~ .custom-control-label::after {
  background-color: #FFFFFF;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.75rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #7B7979;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #BDBBBC;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #D3D2D2 #D3D2D2 #BDBBBC;
}
.nav-tabs .nav-link.disabled {
  color: #7B7979;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #231F20;
  background-color: #FFFFFF;
  border-color: #918F8F #918F8F #FFFFFF;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.75rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #3A5CE9;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav.sub-nav {
  margin: 0;
  border-bottom: 1px solid #918F8F;
}
.nav.sub-nav .nav-item {
  margin-right: 0.5rem;
}
.nav.sub-nav .nav-item:last-child {
  margin-right: 0;
}
.nav.sub-nav .nav-link {
  color: #3A5CE9;
  position: relative;
}
.nav.sub-nav .nav-link::before {
  content: attr(data-label);
  font-weight: bold;
  visibility: hidden;
}
.nav.sub-nav .nav-link:hover, .nav.sub-nav .nav-link:focus {
  font-weight: 700;
}
.nav.sub-nav .nav-link.active {
  font-weight: 700;
}
.nav.sub-nav .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  width: 100%;
  border-radius: 0.75rem;
  display: block;
  height: 0.25rem;
  background-color: #0AB5E0;
}
.nav.sub-nav .nav-link.disabled {
  color: #7B7979;
}
.nav.sub-nav .nav-link-label {
  text-align: center;
  position: absolute;
  top: 0.75rem;
  bottom: 0.75rem;
  right: 1rem;
  left: 1rem;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1.5rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1231.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1232px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #3A5CE9;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #3A5CE9;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(58, 92, 233, 0.904);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(58, 92, 233, 0.95);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(58, 92, 233, 0.765);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #3A5CE9;
}
.navbar-light .navbar-toggler {
  color: rgba(58, 92, 233, 0.904);
  border-color: rgba(58, 92, 233, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(58, 92, 233, 0.904)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(58, 92, 233, 0.904);
}
.navbar-light .navbar-text a {
  color: #3A5CE9;
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #3A5CE9;
}

.navbar-dark .navbar-brand {
  color: #FFFFFF;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFFFFF;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.87);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.97);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.87);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.87)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.87);
}
.navbar-dark .navbar-text a {
  color: #FFFFFF;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #FFFFFF;
}

.navbar .nav-item {
  margin: 0 0.5rem;
}
.navbar .nav-item.active {
  font-weight: 700;
}
.navbar .nav-item .nav-link.dropdown-toggle .label-username {
  display: inline-block;
  position: relative;
}
.navbar .nav-item .nav-link.dropdown-toggle .label-username::before {
  content: attr(data-label);
  font-weight: bold;
  visibility: hidden;
}
.navbar .nav-item .nav-link.dropdown-toggle .label-username:hover, .navbar .nav-item .nav-link.dropdown-toggle .label-username:focus {
  font-weight: 700;
}
.navbar .nav-item .nav-link.dropdown-toggle .nav-link-label {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.navbar .nav-item .nav-link:not(.dropdown-toggle), .navbar .nav-item .nav-link:not(.disabled) {
  position: relative;
}
.navbar .nav-item .nav-link:not(.dropdown-toggle)::before, .navbar .nav-item .nav-link:not(.disabled)::before {
  content: attr(data-label);
  font-weight: bold;
  visibility: hidden;
}
.navbar .nav-item .nav-link:not(.dropdown-toggle):hover, .navbar .nav-item .nav-link:not(.dropdown-toggle):focus, .navbar .nav-item .nav-link:not(.disabled):hover, .navbar .nav-item .nav-link:not(.disabled):focus {
  font-weight: 700;
}
.navbar .nav-item .nav-link:not(.dropdown-toggle).active, .navbar .nav-item .nav-link:not(.disabled).active {
  font-weight: 700;
}
.navbar .nav-link-label {
  text-align: center;
  position: absolute;
  top: 0.75rem;
  bottom: 0.75rem;
  right: 0.5rem;
  left: 0.5rem;
}
.navbar.navbar-link-underline .nav-item.active .nav-link {
  position: relative;
}
.navbar.navbar-link-underline .nav-item.active .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  border-radius: 0.75rem;
  display: block;
  height: 0.25rem;
  background-color: #B4F6F5;
}
.navbar .container-xl,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg {
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar .badge.badge-username {
  color: #FFFFFF;
  padding: 0;
  font-size: 1rem;
  line-height: calc(2rem - 2px);
  text-align: center;
  border-radius: 2rem;
  margin: -0.5rem 0.4rem -0.5rem 0;
  min-width: 2rem;
  text-transform: uppercase;
  background-color: #151A6D;
}

.navbar-brand {
  margin-right: 1rem;
}
.navbar-brand.navbar-logo {
  font-size: initial;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-brand.navbar-logo img {
  height: 2rem;
}

.navbar-toggler {
  border: none;
  margin-right: 1rem;
}

.navbar-light .navbar-toggler {
  color: #3A5CE9;
}

.navbar-dark .navbar-toggler {
  color: #FFFFFF;
}

.help-navbar {
  position: relative;
  z-index: 1;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #918F8F;
}
.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #918F8F;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.75rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-left: 1rem;
  }
}

.card-group > .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card-title-nav .nav {
  margin: -0.5rem -1.3rem;
}
.card-title-nav .card-title {
  margin-top: 0;
}

.card-subtitle {
  margin-top: 0;
}

.card-link {
  margin-right: 1rem;
}
.card-link + .card-link, .card-link.float-right {
  margin-left: 0;
  margin-right: 0;
}

.accordion > .card.active {
  border-color: #3A5CE9;
  overflow: visible;
}
.accordion > .card > .card-header {
  padding: calc(0.75rem - 1px) 1rem;
  background-color: #FFFFFF;
}
.accordion > .card > .card-header .btn {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  box-shadow: none;
  outline: none;
}
.accordion > .card > div > .card-body {
  background-color: #FFFFFF;
  border-top: 1px solid #918F8F;
}
.accordion > .card:first-of-type.active > .card-header {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.accordion > .card:last-of-type.active .card-body {
  border-bottom-left-radius: calc(0.75rem - 1px);
  border-bottom-right-radius: calc(0.75rem - 1px);
}
.accordion.simple-accordion > .card:hover {
  border-color: #3A5CE9;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.75rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #706D6E;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #706D6E;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.625rem 1rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3A5CE9;
  background-color: transparent;
  border: 1px solid transparent;
}
.page-link:hover {
  z-index: 2;
  color: #1637c1;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.75);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.page-item.disabled .page-link {
  color: #7B7979;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: transparent;
}

.pagination-lg .page-link {
  padding: 0.5rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination {
  border-radius: none;
}
.pagination .page-item.disabled .page-link {
  background-color: transparent;
  color: #231F20;
  opacity: 0.65;
}
.pagination .page-link {
  border-radius: 2rem;
}
.pagination .page-link:hover {
  text-decoration: underline;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #FFFFFF;
  background-color: #3A5CE9;
}
.badge-primary.badge-outline {
  border: 1px solid #3A5CE9;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #FFFFFF;
  background-color: #183dd8;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(58, 92, 233, 0.5);
}

.badge-secondary {
  color: #FFFFFF;
  background-color: #2998E3;
}
.badge-secondary.badge-outline {
  border: 1px solid #2998E3;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #FFFFFF;
  background-color: #197dc0;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(41, 152, 227, 0.5);
}

.badge-success {
  color: #FFFFFF;
  background-color: #069D06;
}
.badge-success.badge-outline {
  border: 1px solid #069D06;
}
a.badge-success:hover, a.badge-success:focus {
  color: #FFFFFF;
  background-color: #046c04;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(6, 157, 6, 0.5);
}

.badge-info {
  color: #FFFFFF;
  background-color: #6C2FAC;
}
.badge-info.badge-outline {
  border: 1px solid #6C2FAC;
}
a.badge-info:hover, a.badge-info:focus {
  color: #FFFFFF;
  background-color: #532484;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(108, 47, 172, 0.5);
}

.badge-warning {
  color: #393536;
  background-color: #FFA400;
}
.badge-warning.badge-outline {
  border: 1px solid #FFA400;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #393536;
  background-color: #cc8300;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(255, 164, 0, 0.5);
}

.badge-danger {
  color: #FFFFFF;
  background-color: #DC1439;
}
.badge-danger.badge-outline {
  border: 1px solid #DC1439;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #FFFFFF;
  background-color: #ad102d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(220, 20, 57, 0.5);
}

.badge-light {
  color: #393536;
  background-color: #E9E8E8;
}
.badge-light.badge-outline {
  border: 1px solid #E9E8E8;
}
a.badge-light:hover, a.badge-light:focus {
  color: #393536;
  background-color: #d0cece;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(233, 232, 232, 0.5);
}

.badge-dark {
  color: #FFFFFF;
  background-color: #393536;
}
.badge-dark.badge-outline {
  border: 1px solid #393536;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #FFFFFF;
  background-color: #1f1c1d;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(57, 53, 54, 0.5);
}

.badge-success-dark {
  color: #FFFFFF;
  background-color: #058A05;
}
.badge-success-dark.badge-outline {
  border: 1px solid #058A05;
}
a.badge-success-dark:hover, a.badge-success-dark:focus {
  color: #FFFFFF;
  background-color: #035903;
}
a.badge-success-dark:focus, a.badge-success-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem rgba(5, 138, 5, 0.5);
}

.badge.badge-pill.status {
  width: 5rem;
}
.badge.badge-pill.badge-outline {
  background-color: transparent;
  color: #231F20;
  padding-top: calc(0.25em - 1px);
  padding-bottom: calc(0.25em - 1px);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #E9E8E8;
  border-radius: 1rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 1.5rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0 0;
  color: inherit;
}

.alert-primary {
  color: #17255d;
  background-color: white;
  border-color: #3a5ce9;
}
.alert-primary hr {
  border-top-color: #2349e6;
}
.alert-primary .alert-link {
  color: #0d1534;
}

.alert-secondary {
  color: #103d5b;
  background-color: white;
  border-color: #2998e3;
}
.alert-secondary hr {
  border-top-color: #1c8bd6;
}
.alert-secondary .alert-link {
  color: #082030;
}

.alert-success {
  color: #023f02;
  background-color: white;
  border-color: #069d06;
}
.alert-success hr {
  border-top-color: #058405;
}
.alert-success .alert-link {
  color: #000e00;
}

.alert-info {
  color: #2b1345;
  background-color: white;
  border-color: #6c2fac;
}
.alert-info hr {
  border-top-color: #5f2a98;
}
.alert-info .alert-link {
  color: #12081d;
}

.alert-warning {
  color: #664200;
  background-color: white;
  border-color: #ffa400;
}
.alert-warning hr {
  border-top-color: #e69400;
}
.alert-warning .alert-link {
  color: #332100;
}

.alert-danger {
  color: #580817;
  background-color: white;
  border-color: #dc1439;
}
.alert-danger hr {
  border-top-color: #c51233;
}
.alert-danger .alert-link {
  color: #29040b;
}

.alert-light {
  color: #5d5d5d;
  background-color: white;
  border-color: #e9e8e8;
}
.alert-light hr {
  border-top-color: #dddbdb;
}
.alert-light .alert-link {
  color: #444444;
}

.alert-dark {
  color: #171516;
  background-color: white;
  border-color: #393536;
}
.alert-dark hr {
  border-top-color: #2c2929;
}
.alert-dark .alert-link {
  color: black;
}

.alert-success-dark {
  color: #023702;
  background-color: white;
  border-color: #058a05;
}
.alert-success-dark hr {
  border-top-color: #047104;
}
.alert-success-dark .alert-link {
  color: #000600;
}

.alert {
  color: #231F20;
  display: table;
}
.alert.alert-primary {
  background-color: #FFFFFF;
}
.alert.alert-primary .alert-icon {
  background-color: #3A5CE9;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-secondary {
  background-color: #FFFFFF;
}
.alert.alert-secondary .alert-icon {
  background-color: #2998E3;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-success {
  background-color: #FFFFFF;
}
.alert.alert-success .alert-icon {
  background-color: #069D06;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-info {
  background-color: #FFFFFF;
}
.alert.alert-info .alert-icon {
  background-color: #6C2FAC;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-warning {
  background-color: #FFFFFF;
}
.alert.alert-warning .alert-icon {
  background-color: #FFA400;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-danger {
  background-color: #FFFFFF;
}
.alert.alert-danger .alert-icon {
  background-color: #DC1439;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-light {
  background-color: #FFFFFF;
}
.alert.alert-light .alert-icon {
  background-color: #E9E8E8;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-dark {
  background-color: #FFFFFF;
}
.alert.alert-dark .alert-icon {
  background-color: #393536;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert.alert-success-dark {
  background-color: #FFFFFF;
}
.alert.alert-success-dark .alert-icon {
  background-color: #058A05;
  font-size: 1.5rem;
  color: #FFFFFF;
  top: initial;
}
.alert .alert-link {
  font-weight: 600;
}
.alert.alert-dismissible {
  padding-right: 0;
}
.alert.alert-dismissible .close {
  padding: 0.75rem 1rem;
  position: initial;
  font-size: 1.25rem;
}
.alert.alert-dismissible .close .material-icons-round {
  top: initial;
}
.alert .alert-icon,
.alert .text,
.alert .close,
.alert .action {
  display: table-cell;
  vertical-align: middle;
}
.alert .alert-icon {
  padding: 0.5rem 0.75rem;
  border-radius: 7px 0 0 7px;
}
.alert .text {
  width: 100%;
  padding: 0.75rem 1rem;
}
.alert .action {
  color: #3A5CE9;
  padding: 0.75rem 1rem;
  white-space: nowrap;
}
.alert .action .material-icons-round {
  font-size: 1.4rem;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #D3D2D2;
  border-radius: 0.75rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #3A5CE9;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.75rem;
}

.list-group-item-action {
  width: 100%;
  color: #231F20;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #231F20;
  text-decoration: none;
  background-color: #E9E8E8;
}
.list-group-item-action:active {
  color: #231F20;
  background-color: #D3D2D2;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #FFFFFF;
  border: 1px solid #918F8F;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #7B7979;
  pointer-events: none;
  background-color: #FFFFFF;
}
.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #3A5CE9;
  border-color: #3A5CE9;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1232px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #17255d;
  background-color: #ebeffd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #17255d;
  background-color: #d4dcfb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #17255d;
  border-color: #17255d;
}

.list-group-item-secondary {
  color: #103d5b;
  background-color: #eaf5fc;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #103d5b;
  background-color: #d4eaf9;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #103d5b;
  border-color: #103d5b;
}

.list-group-item-success {
  color: #023f02;
  background-color: #e6f5e6;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #023f02;
  background-color: #d4eed4;
}
.list-group-item-success.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #023f02;
  border-color: #023f02;
}

.list-group-item-info {
  color: #2b1345;
  background-color: #f0eaf7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #2b1345;
  background-color: #e3d8f0;
}
.list-group-item-info.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #2b1345;
  border-color: #2b1345;
}

.list-group-item-warning {
  color: #664200;
  background-color: #fff6e6;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664200;
  background-color: #ffedcd;
}
.list-group-item-warning.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #664200;
  border-color: #664200;
}

.list-group-item-danger {
  color: #580817;
  background-color: #fce8eb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #580817;
  background-color: #f9d1d7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #580817;
  border-color: #580817;
}

.list-group-item-light {
  color: #5d5d5d;
  background-color: #fdfdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #5d5d5d;
  background-color: #f0f0f0;
}
.list-group-item-light.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #5d5d5d;
  border-color: #5d5d5d;
}

.list-group-item-dark {
  color: #171516;
  background-color: #ebebeb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #171516;
  background-color: #dedede;
}
.list-group-item-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #171516;
  border-color: #171516;
}

.list-group-item-success-dark {
  color: #023702;
  background-color: #e6f3e6;
}
.list-group-item-success-dark.list-group-item-action:hover, .list-group-item-success-dark.list-group-item-action:focus {
  color: #023702;
  background-color: #d5ebd5;
}
.list-group-item-success-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #023702;
  border-color: #023702;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 348px;
  max-width: 348px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.75rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #7B7979;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.toast {
  border-left-width: 0.75rem;
  font-size: 1rem;
}
.toast .toast-body {
  display: table;
}
.toast .toast-body .toast-icon,
.toast .toast-body .toast-text,
.toast .toast-body .close {
  display: table-cell;
  vertical-align: middle;
}
.toast .toast-body .toast-icon {
  padding-right: 0.75rem;
}
.toast .toast-body .toast-text {
  width: 100%;
}
.toast .toast-body .close {
  padding: 0.75rem;
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
}

.toast-primary {
  color: #231F20;
  background-color: #ebeffd;
  border-color: #3453d2;
}
.toast-primary .toast-icon {
  color: #3453d2;
}

.toast-secondary {
  color: #231F20;
  background-color: #eaf5fc;
  border-color: #2589cc;
}
.toast-secondary .toast-icon {
  color: #2589cc;
}

.toast-success {
  color: #231F20;
  background-color: #e6f5e6;
  border-color: #058d05;
}
.toast-success .toast-icon {
  color: #058d05;
}

.toast-info {
  color: #231F20;
  background-color: #f0eaf7;
  border-color: #612a9b;
}
.toast-info .toast-icon {
  color: #612a9b;
}

.toast-warning {
  color: #231F20;
  background-color: #fff6e6;
  border-color: #e69400;
}
.toast-warning .toast-icon {
  color: #e69400;
}

.toast-danger {
  color: #231F20;
  background-color: #fce8eb;
  border-color: #c61233;
}
.toast-danger .toast-icon {
  color: #c61233;
}

.toast-light {
  color: #231F20;
  background-color: #fdfdfd;
  border-color: #d2d1d1;
}
.toast-light .toast-icon {
  color: #d2d1d1;
}

.toast-dark {
  color: #231F20;
  background-color: #ebebeb;
  border-color: #333031;
}
.toast-dark .toast-icon {
  color: #333031;
}

.toast-success-dark {
  color: #231F20;
  background-color: #e6f3e6;
  border-color: #057c05;
}
.toast-success-dark .toast-icon {
  color: #057c05;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #918F8F;
  border-radius: 0.75rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem 2rem;
  border-bottom: 0.5rem solid #3A5CE9;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.modal-header .close {
  padding: 1.25rem 2rem;
  margin: -1.25rem -2rem -1.25rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.2;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0.5rem solid #3A5CE9;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}
.modal-footer > * {
  margin: 0.75rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1232px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-title {
  margin: 0;
}
.modal-title.text-center {
  padding-left: 1rem;
  padding-right: 1rem;
}
.modal-title.text-center ~ .close {
  margin-left: calc(-2rem/2);
}

.modal-footer {
  border: none;
  padding-top: 0;
}

.modal .close {
  opacity: 0.5;
  outline: none;
  padding: 0.75rem;
}
.modal .close:not(:disabled):not(.disabled):hover, .modal .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.125rem;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #FFFFFF;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #FFFFFF;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #FFFFFF;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #FFFFFF;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #231F20;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 0.75rem;
}

.tooltip .arrow::after {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #918F8F;
}
.bs-tooltip-top .arrow::after, .bs-tooltip-auto[x-placement^=top] .arrow::after {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #FFFFFF;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #918F8F;
}
.bs-tooltip-right .arrow::after, .bs-tooltip-auto[x-placement^=right] .arrow::after {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #FFFFFF;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #918F8F;
}
.bs-tooltip-bottom .arrow::after, .bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #FFFFFF;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #918F8F;
}
.bs-tooltip-left .arrow::after, .bs-tooltip-auto[x-placement^=left] .arrow::after {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #FFFFFF;
}

.tooltip-inner {
  border: 1px solid #918F8F;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #918F8F;
  border-radius: 1rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 1rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #918f8f;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #FFFFFF;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #918f8f;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #FFFFFF;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #918f8f;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #FFFFFF;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #E9E8E8;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #918f8f;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #FFFFFF;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #E9E8E8;
  border-bottom: 1px solid #dddbdb;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #231F20;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.btn .spinner-border {
  margin-top: -0.18em;
  vertical-align: inherit;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3A5CE9 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #183dd8 !important;
}

.bg-primary-lighter {
  background-color: #7C93F1 !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #4e6dec !important;
}

.bg-primary-light {
  background-color: #5976ED !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #2b50e8 !important;
}

.bg-primary-dark {
  background-color: #1B42E5 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #1534b8 !important;
}

.bg-primary-darker {
  background-color: #173ACA !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #122d9c !important;
}

.bg-secondary {
  background-color: #2998E3 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #197dc0 !important;
}

.bg-secondary-lighter {
  background-color: #76BDED !important;
}

a.bg-secondary-lighter:hover, a.bg-secondary-lighter:focus,
button.bg-secondary-lighter:hover,
button.bg-secondary-lighter:focus {
  background-color: #49a7e7 !important;
}

.bg-secondary-light {
  background-color: #4DA9E8 !important;
}

a.bg-secondary-light:hover, a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: #2093e2 !important;
}

.bg-secondary-dark {
  background-color: #1A83C9 !important;
}

a.bg-secondary-dark:hover, a.bg-secondary-dark:focus,
button.bg-secondary-dark:hover,
button.bg-secondary-dark:focus {
  background-color: #14669c !important;
}

.bg-secondary-darker {
  background-color: #166FAB !important;
}

a.bg-secondary-darker:hover, a.bg-secondary-darker:focus,
button.bg-secondary-darker:hover,
button.bg-secondary-darker:focus {
  background-color: #10527e !important;
}

.bg-secondary-100 {
  background-color: #EAF5FC !important;
}

a.bg-secondary-100:hover, a.bg-secondary-100:focus,
button.bg-secondary-100:hover,
button.bg-secondary-100:focus {
  background-color: #bde0f6 !important;
}

.bg-success {
  background-color: #069D06 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #046c04 !important;
}

.bg-success-lighter {
  background-color: #6AC46A !important;
}

a.bg-success-lighter:hover, a.bg-success-lighter:focus,
button.bg-success-lighter:hover,
button.bg-success-lighter:focus {
  background-color: #47b447 !important;
}

.bg-success-light {
  background-color: #38B138 !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #2c8a2c !important;
}

.bg-success-dark {
  background-color: #058A05 !important;
}

a.bg-success-dark:hover, a.bg-success-dark:focus,
button.bg-success-dark:hover,
button.bg-success-dark:focus {
  background-color: #035903 !important;
}

.bg-success-darker {
  background-color: #057E05 !important;
}

a.bg-success-darker:hover, a.bg-success-darker:focus,
button.bg-success-darker:hover,
button.bg-success-darker:focus {
  background-color: #034d03 !important;
}

.bg-info {
  background-color: #6C2FAC !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #532484 !important;
}

.bg-info-lighter {
  background-color: #A782CD !important;
}

a.bg-info-lighter:hover, a.bg-info-lighter:focus,
button.bg-info-lighter:hover,
button.bg-info-lighter:focus {
  background-color: #8d5ebe !important;
}

.bg-info-light {
  background-color: #8959BD !important;
}

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #7041a2 !important;
}

.bg-info-dark {
  background-color: #612A9B !important;
}

a.bg-info-dark:hover, a.bg-info-dark:focus,
button.bg-info-dark:hover,
button.bg-info-dark:focus {
  background-color: #481f73 !important;
}

.bg-linfo-darker {
  background-color: #56268A !important;
}

a.bg-linfo-darker:hover, a.bg-linfo-darker:focus,
button.bg-linfo-darker:hover,
button.bg-linfo-darker:focus {
  background-color: #3d1b62 !important;
}

.bg-warning {
  background-color: #FFA400 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc8300 !important;
}

.bg-warning-lighter {
  background-color: #FFC55E !important;
}

a.bg-warning-lighter:hover, a.bg-warning-lighter:focus,
button.bg-warning-lighter:hover,
button.bg-warning-lighter:focus {
  background-color: #ffb32b !important;
}

.bg-warning-light {
  background-color: #FFB32B !important;
}

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f79e00 !important;
}

.bg-warning-dark {
  background-color: #D08400 !important;
}

a.bg-warning-dark:hover, a.bg-warning-dark:focus,
button.bg-warning-dark:hover,
button.bg-warning-dark:focus {
  background-color: #9d6400 !important;
}

.bg-warning-darker {
  background-color: #B07100 !important;
}

a.bg-warning-darker:hover, a.bg-warning-darker:focus,
button.bg-warning-darker:hover,
button.bg-warning-darker:focus {
  background-color: #7d5000 !important;
}

.bg-danger {
  background-color: #DC1439 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad102d !important;
}

.bg-danger-lighter {
  background-color: #F05975 !important;
}

a.bg-danger-lighter:hover, a.bg-danger-lighter:focus,
button.bg-danger-lighter:hover,
button.bg-danger-lighter:focus {
  background-color: #ec2a4e !important;
}

.bg-danger-light {
  background-color: #EC2D50 !important;
}

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #d31336 !important;
}

.bg-danger-dark {
  background-color: #B7112F !important;
}

a.bg-danger-dark:hover, a.bg-danger-dark:focus,
button.bg-danger-dark:hover,
button.bg-danger-dark:focus {
  background-color: #880d23 !important;
}

.bg-danger-darker {
  background-color: #980E27 !important;
}

a.bg-danger-darker:hover, a.bg-danger-darker:focus,
button.bg-danger-darker:hover,
button.bg-danger-darker:focus {
  background-color: #690a1b !important;
}

.bg-light {
  background-color: #E9E8E8 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d0cece !important;
}

.bg-gray {
  background-color: #918F8F !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #787575 !important;
}

.bg-dark {
  background-color: #393536 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1f1c1d !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray-50 {
  background-color: #F4F4F4 !important;
}

a.bg-gray-50:hover, a.bg-gray-50:focus,
button.bg-gray-50:hover,
button.bg-gray-50:focus {
  background-color: #dbdbdb !important;
}

.bg-gray-100 {
  background-color: #E9E8E8 !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d0cece !important;
}

.bg-gray-200 {
  background-color: #D3D2D2 !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #bab8b8 !important;
}

.bg-gray-300 {
  background-color: #BDBBBC !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #a4a1a3 !important;
}

.bg-gray-400 {
  background-color: #A7A5A6 !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #8e8b8d !important;
}

.bg-gray-500 {
  background-color: #918F8F !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #787575 !important;
}

.bg-gray-600 {
  background-color: #7B7979 !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #616060 !important;
}

.bg-gray-700 {
  background-color: #656262 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #4b4949 !important;
}

.bg-gray-800 {
  background-color: #4F4C4D !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #353334 !important;
}

.bg-gray-900 {
  background-color: #393536 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #1f1c1d !important;
}

.bg-black {
  background-color: #000000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-light-gray {
  background-color: #E6E6E6 !important;
}

a.bg-light-gray:hover, a.bg-light-gray:focus,
button.bg-light-gray:hover,
button.bg-light-gray:focus {
  background-color: #cdcdcd !important;
}

.bg-turquoise {
  background-color: #4CD5F7 !important;
}

a.bg-turquoise:hover, a.bg-turquoise:focus,
button.bg-turquoise:hover,
button.bg-turquoise:focus {
  background-color: #1bcaf5 !important;
}

.bg-turquoise-lighter {
  background-color: #9BE8FB !important;
}

a.bg-turquoise-lighter:hover, a.bg-turquoise-lighter:focus,
button.bg-turquoise-lighter:hover,
button.bg-turquoise-lighter:focus {
  background-color: #6addf9 !important;
}

.bg-turquoise-light {
  background-color: #71DEF9 !important;
}

a.bg-turquoise-light:hover, a.bg-turquoise-light:focus,
button.bg-turquoise-light:hover,
button.bg-turquoise-light:focus {
  background-color: #40d3f7 !important;
}

.bg-turquoise-dark {
  background-color: #1ECAF5 !important;
}

a.bg-turquoise-dark:hover, a.bg-turquoise-dark:focus,
button.bg-turquoise-dark:hover,
button.bg-turquoise-dark:focus {
  background-color: #0aadd6 !important;
}

.bg-turquoise-darker {
  background-color: #0AB5E0 !important;
}

a.bg-turquoise-darker:hover, a.bg-turquoise-darker:focus,
button.bg-turquoise-darker:hover,
button.bg-turquoise-darker:focus {
  background-color: #088eaf !important;
}

.bg-turquoise-10 {
  background-color: #DDF7FD !important;
}

a.bg-turquoise-10:hover, a.bg-turquoise-10:focus,
button.bg-turquoise-10:hover,
button.bg-turquoise-10:focus {
  background-color: #adecfa !important;
}

.bg-charcoal {
  background-color: #231F20 !important;
}

a.bg-charcoal:hover, a.bg-charcoal:focus,
button.bg-charcoal:hover,
button.bg-charcoal:focus {
  background-color: #080707 !important;
}

.bg-charcoal-75 {
  background-color: #5A5758 !important;
}

a.bg-charcoal-75:hover, a.bg-charcoal-75:focus,
button.bg-charcoal-75:hover,
button.bg-charcoal-75:focus {
  background-color: #403e3f !important;
}

.bg-charcoal-50 {
  background-color: #918F8F !important;
}

a.bg-charcoal-50:hover, a.bg-charcoal-50:focus,
button.bg-charcoal-50:hover,
button.bg-charcoal-50:focus {
  background-color: #787575 !important;
}

.bg-charcoal-25 {
  background-color: #C8C7C7 !important;
}

a.bg-charcoal-25:hover, a.bg-charcoal-25:focus,
button.bg-charcoal-25:hover,
button.bg-charcoal-25:focus {
  background-color: #afadad !important;
}

.bg-navy {
  background-color: #1A2188 !important;
}

a.bg-navy:hover, a.bg-navy:focus,
button.bg-navy:hover,
button.bg-navy:focus {
  background-color: #12175d !important;
}

.bg-navy-lighter {
  background-color: #767AB8 !important;
}

a.bg-navy-lighter:hover, a.bg-navy-lighter:focus,
button.bg-navy-lighter:hover,
button.bg-navy-lighter:focus {
  background-color: #565ba5 !important;
}

.bg-navy-light {
  background-color: #484DA0 !important;
}

a.bg-navy-light:hover, a.bg-navy-light:focus,
button.bg-navy-light:hover,
button.bg-navy-light:focus {
  background-color: #383c7d !important;
}

.bg-navy-dark {
  background-color: #151A6D !important;
}

a.bg-navy-dark:hover, a.bg-navy-dark:focus,
button.bg-navy-dark:hover,
button.bg-navy-dark:focus {
  background-color: #0d1042 !important;
}

.bg-navy-darker {
  background-color: #101452 !important;
}

a.bg-navy-darker:hover, a.bg-navy-darker:focus,
button.bg-navy-darker:hover,
button.bg-navy-darker:focus {
  background-color: #080a27 !important;
}

.bg-salmon {
  background-color: #F7758C !important;
}

a.bg-salmon:hover, a.bg-salmon:focus,
button.bg-salmon:hover,
button.bg-salmon:focus {
  background-color: #f44564 !important;
}

.bg-salmon-lighter {
  background-color: #FAA7B5 !important;
}

a.bg-salmon-lighter:hover, a.bg-salmon-lighter:focus,
button.bg-salmon-lighter:hover,
button.bg-salmon-lighter:focus {
  background-color: #f7778c !important;
}

.bg-salmon-light {
  background-color: #F88DA0 !important;
}

a.bg-salmon-light:hover, a.bg-salmon-light:focus,
button.bg-salmon-light:hover,
button.bg-salmon-light:focus {
  background-color: #f55d78 !important;
}

.bg-salmon-dark {
  background-color: #F65D78 !important;
}

a.bg-salmon-dark:hover, a.bg-salmon-dark:focus,
button.bg-salmon-dark:hover,
button.bg-salmon-dark:focus {
  background-color: #f32d50 !important;
}

.bg-salmon-darker {
  background-color: #F54765 !important;
}

a.bg-salmon-darker:hover, a.bg-salmon-darker:focus,
button.bg-salmon-darker:hover,
button.bg-salmon-darker:focus {
  background-color: #f2173d !important;
}

.bg-seafoam {
  background-color: #B4F6F5 !important;
}

a.bg-seafoam:hover, a.bg-seafoam:focus,
button.bg-seafoam:hover,
button.bg-seafoam:focus {
  background-color: #86f1ef !important;
}

.bg-seafoam-lighter {
  background-color: #D2FAF9 !important;
}

a.bg-seafoam-lighter:hover, a.bg-seafoam-lighter:focus,
button.bg-seafoam-lighter:hover,
button.bg-seafoam-lighter:focus {
  background-color: #a4f5f3 !important;
}

.bg-seafoam-light {
  background-color: #C3F8F7 !important;
}

a.bg-seafoam-light:hover, a.bg-seafoam-light:focus,
button.bg-seafoam-light:hover,
button.bg-seafoam-light:focus {
  background-color: #95f3f1 !important;
}

.bg-seafoam-dark {
  background-color: #99F3F1 !important;
}

a.bg-seafoam-dark:hover, a.bg-seafoam-dark:focus,
button.bg-seafoam-dark:hover,
button.bg-seafoam-dark:focus {
  background-color: #6beeeb !important;
}

.bg-seafoam-darker {
  background-color: #80F0EE !important;
}

a.bg-seafoam-darker:hover, a.bg-seafoam-darker:focus,
button.bg-seafoam-darker:hover,
button.bg-seafoam-darker:focus {
  background-color: #52ebe8 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #918F8F !important;
}

.border-top {
  border-top: 1px solid #918F8F !important;
}

.border-right {
  border-right: 1px solid #918F8F !important;
}

.border-bottom {
  border-bottom: 1px solid #918F8F !important;
}

.border-left {
  border-left: 1px solid #918F8F !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3A5CE9 !important;
}

.border-primary-lighter {
  border-color: #7C93F1 !important;
}

.border-primary-light {
  border-color: #5976ED !important;
}

.border-primary-dark {
  border-color: #1B42E5 !important;
}

.border-primary-darker {
  border-color: #173ACA !important;
}

.border-secondary {
  border-color: #2998E3 !important;
}

.border-secondary-lighter {
  border-color: #76BDED !important;
}

.border-secondary-light {
  border-color: #4DA9E8 !important;
}

.border-secondary-dark {
  border-color: #1A83C9 !important;
}

.border-secondary-darker {
  border-color: #166FAB !important;
}

.border-secondary-100 {
  border-color: #EAF5FC !important;
}

.border-success {
  border-color: #069D06 !important;
}

.border-success-lighter {
  border-color: #6AC46A !important;
}

.border-success-light {
  border-color: #38B138 !important;
}

.border-success-dark {
  border-color: #058A05 !important;
}

.border-success-darker {
  border-color: #057E05 !important;
}

.border-info {
  border-color: #6C2FAC !important;
}

.border-info-lighter {
  border-color: #A782CD !important;
}

.border-info-light {
  border-color: #8959BD !important;
}

.border-info-dark {
  border-color: #612A9B !important;
}

.border-linfo-darker {
  border-color: #56268A !important;
}

.border-warning {
  border-color: #FFA400 !important;
}

.border-warning-lighter {
  border-color: #FFC55E !important;
}

.border-warning-light {
  border-color: #FFB32B !important;
}

.border-warning-dark {
  border-color: #D08400 !important;
}

.border-warning-darker {
  border-color: #B07100 !important;
}

.border-danger {
  border-color: #DC1439 !important;
}

.border-danger-lighter {
  border-color: #F05975 !important;
}

.border-danger-light {
  border-color: #EC2D50 !important;
}

.border-danger-dark {
  border-color: #B7112F !important;
}

.border-danger-darker {
  border-color: #980E27 !important;
}

.border-light {
  border-color: #E9E8E8 !important;
}

.border-gray {
  border-color: #918F8F !important;
}

.border-dark {
  border-color: #393536 !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.border-gray-50 {
  border-color: #F4F4F4 !important;
}

.border-gray-100 {
  border-color: #E9E8E8 !important;
}

.border-gray-200 {
  border-color: #D3D2D2 !important;
}

.border-gray-300 {
  border-color: #BDBBBC !important;
}

.border-gray-400 {
  border-color: #A7A5A6 !important;
}

.border-gray-500 {
  border-color: #918F8F !important;
}

.border-gray-600 {
  border-color: #7B7979 !important;
}

.border-gray-700 {
  border-color: #656262 !important;
}

.border-gray-800 {
  border-color: #4F4C4D !important;
}

.border-gray-900 {
  border-color: #393536 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-light-gray {
  border-color: #E6E6E6 !important;
}

.border-turquoise {
  border-color: #4CD5F7 !important;
}

.border-turquoise-lighter {
  border-color: #9BE8FB !important;
}

.border-turquoise-light {
  border-color: #71DEF9 !important;
}

.border-turquoise-dark {
  border-color: #1ECAF5 !important;
}

.border-turquoise-darker {
  border-color: #0AB5E0 !important;
}

.border-turquoise-10 {
  border-color: #DDF7FD !important;
}

.border-charcoal {
  border-color: #231F20 !important;
}

.border-charcoal-75 {
  border-color: #5A5758 !important;
}

.border-charcoal-50 {
  border-color: #918F8F !important;
}

.border-charcoal-25 {
  border-color: #C8C7C7 !important;
}

.border-navy {
  border-color: #1A2188 !important;
}

.border-navy-lighter {
  border-color: #767AB8 !important;
}

.border-navy-light {
  border-color: #484DA0 !important;
}

.border-navy-dark {
  border-color: #151A6D !important;
}

.border-navy-darker {
  border-color: #101452 !important;
}

.border-salmon {
  border-color: #F7758C !important;
}

.border-salmon-lighter {
  border-color: #FAA7B5 !important;
}

.border-salmon-light {
  border-color: #F88DA0 !important;
}

.border-salmon-dark {
  border-color: #F65D78 !important;
}

.border-salmon-darker {
  border-color: #F54765 !important;
}

.border-seafoam {
  border-color: #B4F6F5 !important;
}

.border-seafoam-lighter {
  border-color: #D2FAF9 !important;
}

.border-seafoam-light {
  border-color: #C3F8F7 !important;
}

.border-seafoam-dark {
  border-color: #99F3F1 !important;
}

.border-seafoam-darker {
  border-color: #80F0EE !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.rounded-sm {
  border-radius: 0.5rem !important;
}

.rounded {
  border-radius: 0.75rem !important;
}

.rounded-top {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-right {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-left {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-lg {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .card-title-nav {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1232px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row, .card-title-nav {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1232px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1232px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top, .table-sticky-header thead th {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow, .table-hover tbody tr:hover, .table-hover.table-striped tbody tr:nth-of-type(odd):hover, .table-hover.table-light-striped tbody tr:nth-of-type(odd):hover, .accordion > .card.active, .tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .dropdown-header,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0, .dropdown-header,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 4rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.mt-9,
.my-9 {
  margin-top: 6rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 6rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 6rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 6rem !important;
}

.m-10 {
  margin: 8rem !important;
}

.mt-10,
.my-10 {
  margin-top: 8rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 8rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 8rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pr-8,
.px-8 {
  padding-right: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.pl-8,
.px-8 {
  padding-left: 4rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.pt-9,
.py-9 {
  padding-top: 6rem !important;
}

.pr-9,
.px-9 {
  padding-right: 6rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 6rem !important;
}

.pl-9,
.px-9 {
  padding-left: 6rem !important;
}

.p-10 {
  padding: 8rem !important;
}

.pt-10,
.py-10 {
  padding-top: 8rem !important;
}

.pr-10,
.px-10 {
  padding-right: 8rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 8rem !important;
}

.pl-10,
.px-10 {
  padding-left: 8rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important;
}

.m-n6 {
  margin: -2rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -4rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -4rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -4rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -4rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -4rem !important;
}

.m-n9 {
  margin: -6rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -6rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -6rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -6rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -6rem !important;
}

.m-n10 {
  margin: -8rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -8rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -8rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -8rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 0.75rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.5rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 1.5rem !important;
  }

  .m-sm-6 {
    margin: 2rem !important;
  }

  .mt-sm-6,
.my-sm-6 {
    margin-top: 2rem !important;
  }

  .mr-sm-6,
.mx-sm-6 {
    margin-right: 2rem !important;
  }

  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 2rem !important;
  }

  .ml-sm-6,
.mx-sm-6 {
    margin-left: 2rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .mt-sm-7,
.my-sm-7 {
    margin-top: 3rem !important;
  }

  .mr-sm-7,
.mx-sm-7 {
    margin-right: 3rem !important;
  }

  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-7,
.mx-sm-7 {
    margin-left: 3rem !important;
  }

  .m-sm-8 {
    margin: 4rem !important;
  }

  .mt-sm-8,
.my-sm-8 {
    margin-top: 4rem !important;
  }

  .mr-sm-8,
.mx-sm-8 {
    margin-right: 4rem !important;
  }

  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 4rem !important;
  }

  .ml-sm-8,
.mx-sm-8 {
    margin-left: 4rem !important;
  }

  .m-sm-9 {
    margin: 6rem !important;
  }

  .mt-sm-9,
.my-sm-9 {
    margin-top: 6rem !important;
  }

  .mr-sm-9,
.mx-sm-9 {
    margin-right: 6rem !important;
  }

  .mb-sm-9,
.my-sm-9 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-9,
.mx-sm-9 {
    margin-left: 6rem !important;
  }

  .m-sm-10 {
    margin: 8rem !important;
  }

  .mt-sm-10,
.my-sm-10 {
    margin-top: 8rem !important;
  }

  .mr-sm-10,
.mx-sm-10 {
    margin-right: 8rem !important;
  }

  .mb-sm-10,
.my-sm-10 {
    margin-bottom: 8rem !important;
  }

  .ml-sm-10,
.mx-sm-10 {
    margin-left: 8rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.5rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 1.5rem !important;
  }

  .p-sm-6 {
    padding: 2rem !important;
  }

  .pt-sm-6,
.py-sm-6 {
    padding-top: 2rem !important;
  }

  .pr-sm-6,
.px-sm-6 {
    padding-right: 2rem !important;
  }

  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 2rem !important;
  }

  .pl-sm-6,
.px-sm-6 {
    padding-left: 2rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .pt-sm-7,
.py-sm-7 {
    padding-top: 3rem !important;
  }

  .pr-sm-7,
.px-sm-7 {
    padding-right: 3rem !important;
  }

  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-7,
.px-sm-7 {
    padding-left: 3rem !important;
  }

  .p-sm-8 {
    padding: 4rem !important;
  }

  .pt-sm-8,
.py-sm-8 {
    padding-top: 4rem !important;
  }

  .pr-sm-8,
.px-sm-8 {
    padding-right: 4rem !important;
  }

  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 4rem !important;
  }

  .pl-sm-8,
.px-sm-8 {
    padding-left: 4rem !important;
  }

  .p-sm-9 {
    padding: 6rem !important;
  }

  .pt-sm-9,
.py-sm-9 {
    padding-top: 6rem !important;
  }

  .pr-sm-9,
.px-sm-9 {
    padding-right: 6rem !important;
  }

  .pb-sm-9,
.py-sm-9 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-9,
.px-sm-9 {
    padding-left: 6rem !important;
  }

  .p-sm-10 {
    padding: 8rem !important;
  }

  .pt-sm-10,
.py-sm-10 {
    padding-top: 8rem !important;
  }

  .pr-sm-10,
.px-sm-10 {
    padding-right: 8rem !important;
  }

  .pb-sm-10,
.py-sm-10 {
    padding-bottom: 8rem !important;
  }

  .pl-sm-10,
.px-sm-10 {
    padding-left: 8rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.5rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n6 {
    margin: -2rem !important;
  }

  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -2rem !important;
  }

  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -2rem !important;
  }

  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -2rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -3rem !important;
  }

  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -3rem !important;
  }

  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -3rem !important;
  }

  .m-sm-n8 {
    margin: -4rem !important;
  }

  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -4rem !important;
  }

  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -4rem !important;
  }

  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -4rem !important;
  }

  .m-sm-n9 {
    margin: -6rem !important;
  }

  .mt-sm-n9,
.my-sm-n9 {
    margin-top: -6rem !important;
  }

  .mr-sm-n9,
.mx-sm-n9 {
    margin-right: -6rem !important;
  }

  .mb-sm-n9,
.my-sm-n9 {
    margin-bottom: -6rem !important;
  }

  .ml-sm-n9,
.mx-sm-n9 {
    margin-left: -6rem !important;
  }

  .m-sm-n10 {
    margin: -8rem !important;
  }

  .mt-sm-n10,
.my-sm-n10 {
    margin-top: -8rem !important;
  }

  .mr-sm-n10,
.mx-sm-n10 {
    margin-right: -8rem !important;
  }

  .mb-sm-n10,
.my-sm-n10 {
    margin-bottom: -8rem !important;
  }

  .ml-sm-n10,
.mx-sm-n10 {
    margin-left: -8rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 0.75rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 0.75rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1rem !important;
  }

  .m-md-5 {
    margin: 1.5rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 1.5rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 1.5rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 1.5rem !important;
  }

  .m-md-6 {
    margin: 2rem !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 2rem !important;
  }

  .mr-md-6,
.mx-md-6 {
    margin-right: 2rem !important;
  }

  .mb-md-6,
.my-md-6 {
    margin-bottom: 2rem !important;
  }

  .ml-md-6,
.mx-md-6 {
    margin-left: 2rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .mt-md-7,
.my-md-7 {
    margin-top: 3rem !important;
  }

  .mr-md-7,
.mx-md-7 {
    margin-right: 3rem !important;
  }

  .mb-md-7,
.my-md-7 {
    margin-bottom: 3rem !important;
  }

  .ml-md-7,
.mx-md-7 {
    margin-left: 3rem !important;
  }

  .m-md-8 {
    margin: 4rem !important;
  }

  .mt-md-8,
.my-md-8 {
    margin-top: 4rem !important;
  }

  .mr-md-8,
.mx-md-8 {
    margin-right: 4rem !important;
  }

  .mb-md-8,
.my-md-8 {
    margin-bottom: 4rem !important;
  }

  .ml-md-8,
.mx-md-8 {
    margin-left: 4rem !important;
  }

  .m-md-9 {
    margin: 6rem !important;
  }

  .mt-md-9,
.my-md-9 {
    margin-top: 6rem !important;
  }

  .mr-md-9,
.mx-md-9 {
    margin-right: 6rem !important;
  }

  .mb-md-9,
.my-md-9 {
    margin-bottom: 6rem !important;
  }

  .ml-md-9,
.mx-md-9 {
    margin-left: 6rem !important;
  }

  .m-md-10 {
    margin: 8rem !important;
  }

  .mt-md-10,
.my-md-10 {
    margin-top: 8rem !important;
  }

  .mr-md-10,
.mx-md-10 {
    margin-right: 8rem !important;
  }

  .mb-md-10,
.my-md-10 {
    margin-bottom: 8rem !important;
  }

  .ml-md-10,
.mx-md-10 {
    margin-left: 8rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 0.75rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 0.75rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1rem !important;
  }

  .p-md-5 {
    padding: 1.5rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 1.5rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 1.5rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 1.5rem !important;
  }

  .p-md-6 {
    padding: 2rem !important;
  }

  .pt-md-6,
.py-md-6 {
    padding-top: 2rem !important;
  }

  .pr-md-6,
.px-md-6 {
    padding-right: 2rem !important;
  }

  .pb-md-6,
.py-md-6 {
    padding-bottom: 2rem !important;
  }

  .pl-md-6,
.px-md-6 {
    padding-left: 2rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .pt-md-7,
.py-md-7 {
    padding-top: 3rem !important;
  }

  .pr-md-7,
.px-md-7 {
    padding-right: 3rem !important;
  }

  .pb-md-7,
.py-md-7 {
    padding-bottom: 3rem !important;
  }

  .pl-md-7,
.px-md-7 {
    padding-left: 3rem !important;
  }

  .p-md-8 {
    padding: 4rem !important;
  }

  .pt-md-8,
.py-md-8 {
    padding-top: 4rem !important;
  }

  .pr-md-8,
.px-md-8 {
    padding-right: 4rem !important;
  }

  .pb-md-8,
.py-md-8 {
    padding-bottom: 4rem !important;
  }

  .pl-md-8,
.px-md-8 {
    padding-left: 4rem !important;
  }

  .p-md-9 {
    padding: 6rem !important;
  }

  .pt-md-9,
.py-md-9 {
    padding-top: 6rem !important;
  }

  .pr-md-9,
.px-md-9 {
    padding-right: 6rem !important;
  }

  .pb-md-9,
.py-md-9 {
    padding-bottom: 6rem !important;
  }

  .pl-md-9,
.px-md-9 {
    padding-left: 6rem !important;
  }

  .p-md-10 {
    padding: 8rem !important;
  }

  .pt-md-10,
.py-md-10 {
    padding-top: 8rem !important;
  }

  .pr-md-10,
.px-md-10 {
    padding-right: 8rem !important;
  }

  .pb-md-10,
.py-md-10 {
    padding-bottom: 8rem !important;
  }

  .pl-md-10,
.px-md-10 {
    padding-left: 8rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.5rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -1.5rem !important;
  }

  .m-md-n6 {
    margin: -2rem !important;
  }

  .mt-md-n6,
.my-md-n6 {
    margin-top: -2rem !important;
  }

  .mr-md-n6,
.mx-md-n6 {
    margin-right: -2rem !important;
  }

  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-md-n6,
.mx-md-n6 {
    margin-left: -2rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .mt-md-n7,
.my-md-n7 {
    margin-top: -3rem !important;
  }

  .mr-md-n7,
.mx-md-n7 {
    margin-right: -3rem !important;
  }

  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n7,
.mx-md-n7 {
    margin-left: -3rem !important;
  }

  .m-md-n8 {
    margin: -4rem !important;
  }

  .mt-md-n8,
.my-md-n8 {
    margin-top: -4rem !important;
  }

  .mr-md-n8,
.mx-md-n8 {
    margin-right: -4rem !important;
  }

  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-md-n8,
.mx-md-n8 {
    margin-left: -4rem !important;
  }

  .m-md-n9 {
    margin: -6rem !important;
  }

  .mt-md-n9,
.my-md-n9 {
    margin-top: -6rem !important;
  }

  .mr-md-n9,
.mx-md-n9 {
    margin-right: -6rem !important;
  }

  .mb-md-n9,
.my-md-n9 {
    margin-bottom: -6rem !important;
  }

  .ml-md-n9,
.mx-md-n9 {
    margin-left: -6rem !important;
  }

  .m-md-n10 {
    margin: -8rem !important;
  }

  .mt-md-n10,
.my-md-n10 {
    margin-top: -8rem !important;
  }

  .mr-md-n10,
.mx-md-n10 {
    margin-right: -8rem !important;
  }

  .mb-md-n10,
.my-md-n10 {
    margin-bottom: -8rem !important;
  }

  .ml-md-n10,
.mx-md-n10 {
    margin-left: -8rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 0.75rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.5rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 1.5rem !important;
  }

  .m-lg-6 {
    margin: 2rem !important;
  }

  .mt-lg-6,
.my-lg-6 {
    margin-top: 2rem !important;
  }

  .mr-lg-6,
.mx-lg-6 {
    margin-right: 2rem !important;
  }

  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 2rem !important;
  }

  .ml-lg-6,
.mx-lg-6 {
    margin-left: 2rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .mt-lg-7,
.my-lg-7 {
    margin-top: 3rem !important;
  }

  .mr-lg-7,
.mx-lg-7 {
    margin-right: 3rem !important;
  }

  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-7,
.mx-lg-7 {
    margin-left: 3rem !important;
  }

  .m-lg-8 {
    margin: 4rem !important;
  }

  .mt-lg-8,
.my-lg-8 {
    margin-top: 4rem !important;
  }

  .mr-lg-8,
.mx-lg-8 {
    margin-right: 4rem !important;
  }

  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 4rem !important;
  }

  .ml-lg-8,
.mx-lg-8 {
    margin-left: 4rem !important;
  }

  .m-lg-9 {
    margin: 6rem !important;
  }

  .mt-lg-9,
.my-lg-9 {
    margin-top: 6rem !important;
  }

  .mr-lg-9,
.mx-lg-9 {
    margin-right: 6rem !important;
  }

  .mb-lg-9,
.my-lg-9 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-9,
.mx-lg-9 {
    margin-left: 6rem !important;
  }

  .m-lg-10 {
    margin: 8rem !important;
  }

  .mt-lg-10,
.my-lg-10 {
    margin-top: 8rem !important;
  }

  .mr-lg-10,
.mx-lg-10 {
    margin-right: 8rem !important;
  }

  .mb-lg-10,
.my-lg-10 {
    margin-bottom: 8rem !important;
  }

  .ml-lg-10,
.mx-lg-10 {
    margin-left: 8rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.5rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 1.5rem !important;
  }

  .p-lg-6 {
    padding: 2rem !important;
  }

  .pt-lg-6,
.py-lg-6 {
    padding-top: 2rem !important;
  }

  .pr-lg-6,
.px-lg-6 {
    padding-right: 2rem !important;
  }

  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 2rem !important;
  }

  .pl-lg-6,
.px-lg-6 {
    padding-left: 2rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .pt-lg-7,
.py-lg-7 {
    padding-top: 3rem !important;
  }

  .pr-lg-7,
.px-lg-7 {
    padding-right: 3rem !important;
  }

  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-7,
.px-lg-7 {
    padding-left: 3rem !important;
  }

  .p-lg-8 {
    padding: 4rem !important;
  }

  .pt-lg-8,
.py-lg-8 {
    padding-top: 4rem !important;
  }

  .pr-lg-8,
.px-lg-8 {
    padding-right: 4rem !important;
  }

  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 4rem !important;
  }

  .pl-lg-8,
.px-lg-8 {
    padding-left: 4rem !important;
  }

  .p-lg-9 {
    padding: 6rem !important;
  }

  .pt-lg-9,
.py-lg-9 {
    padding-top: 6rem !important;
  }

  .pr-lg-9,
.px-lg-9 {
    padding-right: 6rem !important;
  }

  .pb-lg-9,
.py-lg-9 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-9,
.px-lg-9 {
    padding-left: 6rem !important;
  }

  .p-lg-10 {
    padding: 8rem !important;
  }

  .pt-lg-10,
.py-lg-10 {
    padding-top: 8rem !important;
  }

  .pr-lg-10,
.px-lg-10 {
    padding-right: 8rem !important;
  }

  .pb-lg-10,
.py-lg-10 {
    padding-bottom: 8rem !important;
  }

  .pl-lg-10,
.px-lg-10 {
    padding-left: 8rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.5rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n6 {
    margin: -2rem !important;
  }

  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -2rem !important;
  }

  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -2rem !important;
  }

  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -2rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -3rem !important;
  }

  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -3rem !important;
  }

  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -3rem !important;
  }

  .m-lg-n8 {
    margin: -4rem !important;
  }

  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -4rem !important;
  }

  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -4rem !important;
  }

  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -4rem !important;
  }

  .m-lg-n9 {
    margin: -6rem !important;
  }

  .mt-lg-n9,
.my-lg-n9 {
    margin-top: -6rem !important;
  }

  .mr-lg-n9,
.mx-lg-n9 {
    margin-right: -6rem !important;
  }

  .mb-lg-n9,
.my-lg-n9 {
    margin-bottom: -6rem !important;
  }

  .ml-lg-n9,
.mx-lg-n9 {
    margin-left: -6rem !important;
  }

  .m-lg-n10 {
    margin: -8rem !important;
  }

  .mt-lg-n10,
.my-lg-n10 {
    margin-top: -8rem !important;
  }

  .mr-lg-n10,
.mx-lg-n10 {
    margin-right: -8rem !important;
  }

  .mb-lg-n10,
.my-lg-n10 {
    margin-bottom: -8rem !important;
  }

  .ml-lg-n10,
.mx-lg-n10 {
    margin-left: -8rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1232px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.5rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 1.5rem !important;
  }

  .m-xl-6 {
    margin: 2rem !important;
  }

  .mt-xl-6,
.my-xl-6 {
    margin-top: 2rem !important;
  }

  .mr-xl-6,
.mx-xl-6 {
    margin-right: 2rem !important;
  }

  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 2rem !important;
  }

  .ml-xl-6,
.mx-xl-6 {
    margin-left: 2rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .mt-xl-7,
.my-xl-7 {
    margin-top: 3rem !important;
  }

  .mr-xl-7,
.mx-xl-7 {
    margin-right: 3rem !important;
  }

  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-7,
.mx-xl-7 {
    margin-left: 3rem !important;
  }

  .m-xl-8 {
    margin: 4rem !important;
  }

  .mt-xl-8,
.my-xl-8 {
    margin-top: 4rem !important;
  }

  .mr-xl-8,
.mx-xl-8 {
    margin-right: 4rem !important;
  }

  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 4rem !important;
  }

  .ml-xl-8,
.mx-xl-8 {
    margin-left: 4rem !important;
  }

  .m-xl-9 {
    margin: 6rem !important;
  }

  .mt-xl-9,
.my-xl-9 {
    margin-top: 6rem !important;
  }

  .mr-xl-9,
.mx-xl-9 {
    margin-right: 6rem !important;
  }

  .mb-xl-9,
.my-xl-9 {
    margin-bottom: 6rem !important;
  }

  .ml-xl-9,
.mx-xl-9 {
    margin-left: 6rem !important;
  }

  .m-xl-10 {
    margin: 8rem !important;
  }

  .mt-xl-10,
.my-xl-10 {
    margin-top: 8rem !important;
  }

  .mr-xl-10,
.mx-xl-10 {
    margin-right: 8rem !important;
  }

  .mb-xl-10,
.my-xl-10 {
    margin-bottom: 8rem !important;
  }

  .ml-xl-10,
.mx-xl-10 {
    margin-left: 8rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.5rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 1.5rem !important;
  }

  .p-xl-6 {
    padding: 2rem !important;
  }

  .pt-xl-6,
.py-xl-6 {
    padding-top: 2rem !important;
  }

  .pr-xl-6,
.px-xl-6 {
    padding-right: 2rem !important;
  }

  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 2rem !important;
  }

  .pl-xl-6,
.px-xl-6 {
    padding-left: 2rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .pt-xl-7,
.py-xl-7 {
    padding-top: 3rem !important;
  }

  .pr-xl-7,
.px-xl-7 {
    padding-right: 3rem !important;
  }

  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-7,
.px-xl-7 {
    padding-left: 3rem !important;
  }

  .p-xl-8 {
    padding: 4rem !important;
  }

  .pt-xl-8,
.py-xl-8 {
    padding-top: 4rem !important;
  }

  .pr-xl-8,
.px-xl-8 {
    padding-right: 4rem !important;
  }

  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 4rem !important;
  }

  .pl-xl-8,
.px-xl-8 {
    padding-left: 4rem !important;
  }

  .p-xl-9 {
    padding: 6rem !important;
  }

  .pt-xl-9,
.py-xl-9 {
    padding-top: 6rem !important;
  }

  .pr-xl-9,
.px-xl-9 {
    padding-right: 6rem !important;
  }

  .pb-xl-9,
.py-xl-9 {
    padding-bottom: 6rem !important;
  }

  .pl-xl-9,
.px-xl-9 {
    padding-left: 6rem !important;
  }

  .p-xl-10 {
    padding: 8rem !important;
  }

  .pt-xl-10,
.py-xl-10 {
    padding-top: 8rem !important;
  }

  .pr-xl-10,
.px-xl-10 {
    padding-right: 8rem !important;
  }

  .pb-xl-10,
.py-xl-10 {
    padding-bottom: 8rem !important;
  }

  .pl-xl-10,
.px-xl-10 {
    padding-left: 8rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.5rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n6 {
    margin: -2rem !important;
  }

  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -2rem !important;
  }

  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -2rem !important;
  }

  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -2rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -3rem !important;
  }

  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -3rem !important;
  }

  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -3rem !important;
  }

  .m-xl-n8 {
    margin: -4rem !important;
  }

  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -4rem !important;
  }

  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -4rem !important;
  }

  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -4rem !important;
  }

  .m-xl-n9 {
    margin: -6rem !important;
  }

  .mt-xl-n9,
.my-xl-n9 {
    margin-top: -6rem !important;
  }

  .mr-xl-n9,
.mx-xl-n9 {
    margin-right: -6rem !important;
  }

  .mb-xl-n9,
.my-xl-n9 {
    margin-bottom: -6rem !important;
  }

  .ml-xl-n9,
.mx-xl-n9 {
    margin-left: -6rem !important;
  }

  .m-xl-n10 {
    margin: -8rem !important;
  }

  .mt-xl-n10,
.my-xl-n10 {
    margin-top: -8rem !important;
  }

  .mr-xl-n10,
.mx-xl-n10 {
    margin-right: -8rem !important;
  }

  .mb-xl-n10,
.my-xl-n10 {
    margin-bottom: -8rem !important;
  }

  .ml-xl-n10,
.mx-xl-n10 {
    margin-left: -8rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.5rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-6 {
    margin: 2rem !important;
  }

  .mt-xxl-6,
.my-xxl-6 {
    margin-top: 2rem !important;
  }

  .mr-xxl-6,
.mx-xxl-6 {
    margin-right: 2rem !important;
  }

  .mb-xxl-6,
.my-xxl-6 {
    margin-bottom: 2rem !important;
  }

  .ml-xxl-6,
.mx-xxl-6 {
    margin-left: 2rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .mt-xxl-7,
.my-xxl-7 {
    margin-top: 3rem !important;
  }

  .mr-xxl-7,
.mx-xxl-7 {
    margin-right: 3rem !important;
  }

  .mb-xxl-7,
.my-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-7,
.mx-xxl-7 {
    margin-left: 3rem !important;
  }

  .m-xxl-8 {
    margin: 4rem !important;
  }

  .mt-xxl-8,
.my-xxl-8 {
    margin-top: 4rem !important;
  }

  .mr-xxl-8,
.mx-xxl-8 {
    margin-right: 4rem !important;
  }

  .mb-xxl-8,
.my-xxl-8 {
    margin-bottom: 4rem !important;
  }

  .ml-xxl-8,
.mx-xxl-8 {
    margin-left: 4rem !important;
  }

  .m-xxl-9 {
    margin: 6rem !important;
  }

  .mt-xxl-9,
.my-xxl-9 {
    margin-top: 6rem !important;
  }

  .mr-xxl-9,
.mx-xxl-9 {
    margin-right: 6rem !important;
  }

  .mb-xxl-9,
.my-xxl-9 {
    margin-bottom: 6rem !important;
  }

  .ml-xxl-9,
.mx-xxl-9 {
    margin-left: 6rem !important;
  }

  .m-xxl-10 {
    margin: 8rem !important;
  }

  .mt-xxl-10,
.my-xxl-10 {
    margin-top: 8rem !important;
  }

  .mr-xxl-10,
.mx-xxl-10 {
    margin-right: 8rem !important;
  }

  .mb-xxl-10,
.my-xxl-10 {
    margin-bottom: 8rem !important;
  }

  .ml-xxl-10,
.mx-xxl-10 {
    margin-left: 8rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.5rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-6 {
    padding: 2rem !important;
  }

  .pt-xxl-6,
.py-xxl-6 {
    padding-top: 2rem !important;
  }

  .pr-xxl-6,
.px-xxl-6 {
    padding-right: 2rem !important;
  }

  .pb-xxl-6,
.py-xxl-6 {
    padding-bottom: 2rem !important;
  }

  .pl-xxl-6,
.px-xxl-6 {
    padding-left: 2rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .pt-xxl-7,
.py-xxl-7 {
    padding-top: 3rem !important;
  }

  .pr-xxl-7,
.px-xxl-7 {
    padding-right: 3rem !important;
  }

  .pb-xxl-7,
.py-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-7,
.px-xxl-7 {
    padding-left: 3rem !important;
  }

  .p-xxl-8 {
    padding: 4rem !important;
  }

  .pt-xxl-8,
.py-xxl-8 {
    padding-top: 4rem !important;
  }

  .pr-xxl-8,
.px-xxl-8 {
    padding-right: 4rem !important;
  }

  .pb-xxl-8,
.py-xxl-8 {
    padding-bottom: 4rem !important;
  }

  .pl-xxl-8,
.px-xxl-8 {
    padding-left: 4rem !important;
  }

  .p-xxl-9 {
    padding: 6rem !important;
  }

  .pt-xxl-9,
.py-xxl-9 {
    padding-top: 6rem !important;
  }

  .pr-xxl-9,
.px-xxl-9 {
    padding-right: 6rem !important;
  }

  .pb-xxl-9,
.py-xxl-9 {
    padding-bottom: 6rem !important;
  }

  .pl-xxl-9,
.px-xxl-9 {
    padding-left: 6rem !important;
  }

  .p-xxl-10 {
    padding: 8rem !important;
  }

  .pt-xxl-10,
.py-xxl-10 {
    padding-top: 8rem !important;
  }

  .pr-xxl-10,
.px-xxl-10 {
    padding-right: 8rem !important;
  }

  .pb-xxl-10,
.py-xxl-10 {
    padding-bottom: 8rem !important;
  }

  .pl-xxl-10,
.px-xxl-10 {
    padding-left: 8rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n6 {
    margin: -2rem !important;
  }

  .mt-xxl-n6,
.my-xxl-n6 {
    margin-top: -2rem !important;
  }

  .mr-xxl-n6,
.mx-xxl-n6 {
    margin-right: -2rem !important;
  }

  .mb-xxl-n6,
.my-xxl-n6 {
    margin-bottom: -2rem !important;
  }

  .ml-xxl-n6,
.mx-xxl-n6 {
    margin-left: -2rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .mt-xxl-n7,
.my-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n7,
.mx-xxl-n7 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n7,
.my-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n7,
.mx-xxl-n7 {
    margin-left: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -4rem !important;
  }

  .mt-xxl-n8,
.my-xxl-n8 {
    margin-top: -4rem !important;
  }

  .mr-xxl-n8,
.mx-xxl-n8 {
    margin-right: -4rem !important;
  }

  .mb-xxl-n8,
.my-xxl-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-xxl-n8,
.mx-xxl-n8 {
    margin-left: -4rem !important;
  }

  .m-xxl-n9 {
    margin: -6rem !important;
  }

  .mt-xxl-n9,
.my-xxl-n9 {
    margin-top: -6rem !important;
  }

  .mr-xxl-n9,
.mx-xxl-n9 {
    margin-right: -6rem !important;
  }

  .mb-xxl-n9,
.my-xxl-n9 {
    margin-bottom: -6rem !important;
  }

  .ml-xxl-n9,
.mx-xxl-n9 {
    margin-left: -6rem !important;
  }

  .m-xxl-n10 {
    margin: -8rem !important;
  }

  .mt-xxl-n10,
.my-xxl-n10 {
    margin-top: -8rem !important;
  }

  .mr-xxl-n10,
.mx-xxl-n10 {
    margin-right: -8rem !important;
  }

  .mb-xxl-n10,
.my-xxl-n10 {
    margin-bottom: -8rem !important;
  }

  .ml-xxl-n10,
.mx-xxl-n10 {
    margin-left: -8rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1232px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: 200 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-primary {
  color: #3A5CE9 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1637c1 !important;
}

.text-primary-lighter {
  color: #7C93F1 !important;
}

a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #375aea !important;
}

.text-primary-light {
  color: #5976ED !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #1840e1 !important;
}

.text-primary-dark {
  color: #1B42E5 !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #122ea1 !important;
}

.text-primary-darker {
  color: #173ACA !important;
}

a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #0f2685 !important;
}

.text-secondary {
  color: #2998E3 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #166ea9 !important;
}

.text-secondary-lighter {
  color: #76BDED !important;
}

a.text-secondary-lighter:hover, a.text-secondary-lighter:focus {
  color: #329ce4 !important;
}

.text-secondary-light {
  color: #4DA9E8 !important;
}

a.text-secondary-light:hover, a.text-secondary-light:focus {
  color: #1b85ce !important;
}

.text-secondary-dark {
  color: #1A83C9 !important;
}

a.text-secondary-dark:hover, a.text-secondary-dark:focus {
  color: #115785 !important;
}

.text-secondary-darker {
  color: #166FAB !important;
}

a.text-secondary-darker:hover, a.text-secondary-darker:focus {
  color: #0d4367 !important;
}

.text-secondary-100 {
  color: #EAF5FC !important;
}

a.text-secondary-100:hover, a.text-secondary-100:focus {
  color: #a7d5f2 !important;
}

.text-success {
  color: #069D06 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #035303 !important;
}

.text-success-lighter {
  color: #6AC46A !important;
}

a.text-success-lighter:hover, a.text-success-lighter:focus {
  color: #40a240 !important;
}

.text-success-light {
  color: #38B138 !important;
}

a.text-success-light:hover, a.text-success-light:focus {
  color: #267726 !important;
}

.text-success-dark {
  color: #058A05 !important;
}

a.text-success-dark:hover, a.text-success-dark:focus {
  color: #024002 !important;
}

.text-success-darker {
  color: #057E05 !important;
}

a.text-success-darker:hover, a.text-success-darker:focus {
  color: #023402 !important;
}

.text-info {
  color: #6C2FAC !important;
}

a.text-info:hover, a.text-info:focus {
  color: #461f70 !important;
}

.text-info-lighter {
  color: #A782CD !important;
}

a.text-info-lighter:hover, a.text-info-lighter:focus {
  color: #814bb7 !important;
}

.text-info-light {
  color: #8959BD !important;
}

a.text-info-light:hover, a.text-info-light:focus {
  color: #633990 !important;
}

.text-info-dark {
  color: #612A9B !important;
}

a.text-info-dark:hover, a.text-info-dark:focus {
  color: #3b1a5f !important;
}

.text-linfo-darker {
  color: #56268A !important;
}

a.text-linfo-darker:hover, a.text-linfo-darker:focus {
  color: #31154e !important;
}

.text-warning {
  color: #FFA400 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b37300 !important;
}

.text-warning-lighter {
  color: #FFC55E !important;
}

a.text-warning-lighter:hover, a.text-warning-lighter:focus {
  color: #ffa912 !important;
}

.text-warning-light {
  color: #FFB32B !important;
}

a.text-warning-light:hover, a.text-warning-light:focus {
  color: #de8e00 !important;
}

.text-warning-dark {
  color: #D08400 !important;
}

a.text-warning-dark:hover, a.text-warning-dark:focus {
  color: #845300 !important;
}

.text-warning-darker {
  color: #B07100 !important;
}

a.text-warning-darker:hover, a.text-warning-darker:focus {
  color: #644000 !important;
}

.text-danger {
  color: #DC1439 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #960e27 !important;
}

.text-danger-lighter {
  color: #F05975 !important;
}

a.text-danger-lighter:hover, a.text-danger-lighter:focus {
  color: #e8153c !important;
}

.text-danger-light {
  color: #EC2D50 !important;
}

a.text-danger-light:hover, a.text-danger-light:focus {
  color: #bc1130 !important;
}

.text-danger-dark {
  color: #B7112F !important;
}

a.text-danger-dark:hover, a.text-danger-dark:focus {
  color: #710a1d !important;
}

.text-danger-darker {
  color: #980E27 !important;
}

a.text-danger-darker:hover, a.text-danger-darker:focus {
  color: #520815 !important;
}

.text-light {
  color: #E9E8E8 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c4c1c1 !important;
}

.text-gray {
  color: #918F8F !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #6b6969 !important;
}

.text-dark {
  color: #393536 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #111010 !important;
}

.text-white {
  color: #FFFFFF !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-gray-50 {
  color: #F4F4F4 !important;
}

a.text-gray-50:hover, a.text-gray-50:focus {
  color: #cecece !important;
}

.text-gray-100 {
  color: #E9E8E8 !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #c4c1c1 !important;
}

.text-gray-200 {
  color: #D3D2D2 !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #adabab !important;
}

.text-gray-300 {
  color: #BDBBBC !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #979496 !important;
}

.text-gray-400 {
  color: #A7A5A6 !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #817e80 !important;
}

.text-gray-500 {
  color: #918F8F !important;
}

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #6b6969 !important;
}

.text-gray-600 {
  color: #7B7979 !important;
}

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #545353 !important;
}

.text-gray-700 {
  color: #656262 !important;
}

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #3e3c3c !important;
}

.text-gray-800 {
  color: #4F4C4D !important;
}

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #282627 !important;
}

.text-gray-900 {
  color: #393536 !important;
}

a.text-gray-900:hover, a.text-gray-900:focus {
  color: #111010 !important;
}

.text-black {
  color: #000000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-light-gray {
  color: #E6E6E6 !important;
}

a.text-light-gray:hover, a.text-light-gray:focus {
  color: silver !important;
}

.text-turquoise {
  color: #4CD5F7 !important;
}

a.text-turquoise:hover, a.text-turquoise:focus {
  color: #0bbfec !important;
}

.text-turquoise-lighter {
  color: #9BE8FB !important;
}

a.text-turquoise-lighter:hover, a.text-turquoise-lighter:focus {
  color: #51d7f8 !important;
}

.text-turquoise-light {
  color: #71DEF9 !important;
}

a.text-turquoise-light:hover, a.text-turquoise-light:focus {
  color: #28cdf6 !important;
}

.text-turquoise-dark {
  color: #1ECAF5 !important;
}

a.text-turquoise-dark:hover, a.text-turquoise-dark:focus {
  color: #089abe !important;
}

.text-turquoise-darker {
  color: #0AB5E0 !important;
}

a.text-turquoise-darker:hover, a.text-turquoise-darker:focus {
  color: #077a97 !important;
}

.text-turquoise-10 {
  color: #DDF7FD !important;
}

a.text-turquoise-10:hover, a.text-turquoise-10:focus {
  color: #95e6f9 !important;
}

.text-charcoal {
  color: #231F20 !important;
}

a.text-charcoal:hover, a.text-charcoal:focus {
  color: black !important;
}

.text-charcoal-75 {
  color: #5A5758 !important;
}

a.text-charcoal-75:hover, a.text-charcoal-75:focus {
  color: #333132 !important;
}

.text-charcoal-50 {
  color: #918F8F !important;
}

a.text-charcoal-50:hover, a.text-charcoal-50:focus {
  color: #6b6969 !important;
}

.text-charcoal-25 {
  color: #C8C7C7 !important;
}

a.text-charcoal-25:hover, a.text-charcoal-25:focus {
  color: #a2a0a0 !important;
}

.text-navy {
  color: #1A2188 !important;
}

a.text-navy:hover, a.text-navy:focus {
  color: #0e1148 !important;
}

.text-navy-lighter {
  color: #767AB8 !important;
}

a.text-navy-lighter:hover, a.text-navy-lighter:focus {
  color: #4d5195 !important;
}

.text-navy-light {
  color: #484DA0 !important;
}

a.text-navy-light:hover, a.text-navy-light:focus {
  color: #30346b !important;
}

.text-navy-dark {
  color: #151A6D !important;
}

a.text-navy-dark:hover, a.text-navy-dark:focus {
  color: #090b2d !important;
}

.text-navy-darker {
  color: #101452 !important;
}

a.text-navy-darker:hover, a.text-navy-darker:focus {
  color: #040412 !important;
}

.text-salmon {
  color: #F7758C !important;
}

a.text-salmon:hover, a.text-salmon:focus {
  color: #f32d50 !important;
}

.text-salmon-lighter {
  color: #FAA7B5 !important;
}

a.text-salmon-lighter:hover, a.text-salmon-lighter:focus {
  color: #f65f78 !important;
}

.text-salmon-light {
  color: #F88DA0 !important;
}

a.text-salmon-light:hover, a.text-salmon-light:focus {
  color: #f44564 !important;
}

.text-salmon-dark {
  color: #F65D78 !important;
}

a.text-salmon-dark:hover, a.text-salmon-dark:focus {
  color: #f2153c !important;
}

.text-salmon-darker {
  color: #F54765 !important;
}

a.text-salmon-darker:hover, a.text-salmon-darker:focus {
  color: #e30c31 !important;
}

.text-seafoam {
  color: #B4F6F5 !important;
}

a.text-seafoam:hover, a.text-seafoam:focus {
  color: #70eeec !important;
}

.text-seafoam-lighter {
  color: #D2FAF9 !important;
}

a.text-seafoam-lighter:hover, a.text-seafoam-lighter:focus {
  color: #8df2f0 !important;
}

.text-seafoam-light {
  color: #C3F8F7 !important;
}

a.text-seafoam-light:hover, a.text-seafoam-light:focus {
  color: #7ef0ee !important;
}

.text-seafoam-dark {
  color: #99F3F1 !important;
}

a.text-seafoam-dark:hover, a.text-seafoam-dark:focus {
  color: #55ebe8 !important;
}

.text-seafoam-darker {
  color: #80F0EE !important;
}

a.text-seafoam-darker:hover, a.text-seafoam-darker:focus {
  color: #3ce8e5 !important;
}

.text-body {
  color: #231F20 !important;
}

.text-muted {
  color: #706D6E !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5 {
  opacity: 1 !important;
}

.gradient-primary {
  background-image: linear-gradient(to right, #95a5e4 0%, #627ada 100%);
  background-repeat: repeat-x;
}

.gradient-secondary {
  background-image: linear-gradient(to right, #D3D2D2 0%, #A7A5A6 100%);
  background-repeat: repeat-x;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #918F8F;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #FFFFFF !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #BDBBBC !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #918F8F;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #918F8F;
  }
}
.lc-dt,
.content-highlight {
  position: relative;
  padding-left: 1rem;
  list-style: none;
}
.lc-dt::before,
.content-highlight::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #2998E3;
  border-radius: 2px;
}

.footer-logo {
  height: 3rem;
}

.lc-footer-accordion {
  padding: 0;
  list-style: none;
}
.lc-footer-accordion > li {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.lc-footer-accordion > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.lc-footer-accordion > li > a {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  padding: 8px 16px;
}
.lc-footer-accordion > li > a:hover, .lc-footer-accordion > li > a.active {
  background: rgba(255, 255, 255, 0.17);
}
.lc-footer-accordion > li > a .material-icons,
.lc-footer-accordion > li > a .material-icons-round {
  margin-top: 0;
}
.lc-footer-accordion > li .content {
  margin: 0;
  padding: 12px 16px;
  list-style: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.3);
}
.lc-footer-accordion > li .content li {
  margin-bottom: 8px;
}
.lc-footer-accordion > li .content li:last-child {
  margin-bottom: 0;
}
.lc-footer-accordion > li .content li a {
  color: #FFFFFF;
}

.sidebar {
  background-color: #F4F4F4;
  width: 18rem;
  border-right: 1px solid #918F8F;
  height: 100%;
}
.sidebar .nav {
  padding: 1.5rem 0;
  margin: 0;
}
.sidebar .nav-link {
  color: #231F20;
  padding: 0.75rem 1rem;
  border-left: 0.25rem solid transparent;
}
.sidebar .nav-link .material-icons-round,
.sidebar .nav-link .material-icons {
  margin-top: 0;
}
.sidebar .nav-link:hover, .sidebar .nav-link.active, .sidebar .nav-link:focus {
  font-weight: 700;
}
.sidebar .nav-link:hover .icon, .sidebar .nav-link.active .icon, .sidebar .nav-link:focus .icon {
  color: #3A5CE9;
}
.sidebar .nav-link:hover {
  background-color: #FFFFFF;
  color: #231F20;
  border-color: #3A5CE9;
}
.sidebar .nav-link.active, .sidebar .nav-link:focus {
  background-color: #FFFFFF;
  color: #231F20;
  border-color: #3A5CE9;
}
.sidebar .sidebar-subnav {
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
}
.sidebar .sidebar-subnav .nav-link {
  border: none;
  padding: 0.5rem 1.5rem 0.5rem 3.75rem;
}
.sidebar .sidebar-subnav .nav-link:hover, .sidebar .sidebar-subnav .nav-link.active, .sidebar .sidebar-subnav .nav-link:focus {
  font-weight: bold;
  background-color: transparent;
}

.sidebar.sidebar-dark {
  background-color: #1A2188;
  border-right: 1px solid #1A2188;
}
.sidebar.sidebar-dark .nav-link {
  color: #FFFFFF;
}
.sidebar.sidebar-dark .nav-link:hover .icon, .sidebar.sidebar-dark .nav-link.active .icon, .sidebar.sidebar-dark .nav-link:focus .icon {
  color: #FFFFFF;
}
.sidebar.sidebar-dark .nav-link:hover {
  background-color: #101452;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.sidebar.sidebar-dark .nav-link.active, .sidebar.sidebar-dark .nav-link:focus {
  background-color: #101452;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.sidebar.sidebar-dark .sidebar-subnav {
  background-color: #101452;
}