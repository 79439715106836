@import "node_modules/bootstrap/scss/type";

//
// Headings
//

// Spacing
h1,
.h1,
h3,
.h3,
h5,
.h5 {
  line-height: 1.3333333;
}

h2,
.h2 {
  line-height: 1.2;
}

h4,
.h4 {
  line-height: 1.4;
}

// Custom Font Styles
h1,
.h1,
h2,
.h2,
h4,
.h4 {
  font-weight: $font-weight-semibold;
}
h3,
.h3,
h5,
.h5 {
  font-weight: $font-weight-normal;
}

h3,
.h3 {
  @include font-size($h3-font-size);
}

h6,
.h6 {
  line-height: 1.5;
  font-weight: $font-weight-bold;
}

.lead {
  line-height: $headings-line-height;
}

// Type display classes

//
// Horizontal rules
//

//
// Emphasis
//

b,
strong {
  font-weight: $font-weight-bold;
}

small,
.small {
  line-height: $line-height-sm;
}
.xsmall {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
}
