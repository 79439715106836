@import "node_modules/bootstrap/scss/card";

.card-title-nav {
  @extend .d-flex;
  @extend .flex-row;
  .nav {
    margin: -0.5rem -1.3rem;
  }
  .card-title {
    margin-top: 0;
  }
}
.card-subtitle {
  margin-top: 0;
}
.card-link {
  margin-right: $card-spacer-x;
  + .card-link,
  &.float-right {
    margin-left: 0;
    margin-right: 0;
  }
}



//
// Accordion
//

.accordion {
  > .card {
    &.active {
      border-color: $accordion-border-active-color;
      overflow: visible;

      @extend .shadow;
    }

    > .card-header {
      padding: calc(#{$card-spacer-y} - 1px) $card-spacer-x;
      background-color: $accordion-bg-color;

      .btn {
        margin-top: $spacer * 0.25;
        margin-bottom: $spacer * 0.25;
        box-shadow: none;
        outline: none;
      }
    }

    > div > .card-body {
      background-color: $white;
      border-top: 1px solid $border-color;
    }
  }

  > .card:first-of-type {
    &.active {
      > .card-header {
        border-top-left-radius: $card-inner-border-radius;
        border-top-right-radius: $card-inner-border-radius;
      }
    }
  }
  > .card:last-of-type {
    &.active {
      .card-body {
        border-bottom-left-radius: $card-inner-border-radius;
        border-bottom-right-radius: $card-inner-border-radius;
      }
    }
  }

  // Simple Accordion
  &.simple-accordion {
    > .card {
      &:hover {
        border-color: $accordion-border-active-color;
      }
    }
  }
}