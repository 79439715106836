@import "node_modules/bootstrap/scss/buttons";

.btn-lg {
  font-weight: $input-btn-font-weight-lg;
}

.btn-link {
  font-weight: $font-weight-bold;
}

.btn-min-width {
  min-width: $btn-min-width;
}

.btn {
  &:focus:not(:focus-visible) {
    outline: $input-btn-focus-width solid transparent !important;
    box-shadow: none !important;
  }
  &.btn-primary {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: $navy;
    }

    &.disabled,
    &:disabled {
      background: $primary;
    }
  }

  &.btn-secondary {
    background: $white;
    color: $primary;
    border-color: $primary;

    @include button-outline-variant($primary);
    
    &.disabled,
    &:disabled {
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        background: $white;
        color: $primary;
        border-color: $primary;
      }
    }
  }

  &.btn-success {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: $green-darker;
    }

    &.disabled,
    &:disabled {
      background: $success;
    }
  }

  &.btn-danger {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: $red-dark;
    }

    &.disabled,
    &:disabled {
      background: $danger;
    }
  }

  &.btn-link {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: $navy;
    }
  }
  &.disabled,
  &:disabled {
    cursor: default;
  }
}

a {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: $navy;
  }

  .text-success {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $green-darker;
    }
  }

  .text-danger {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $red-dark;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background-color: $white;
    @include button-outline-variant($value);
  }
}
