@import "node_modules/bootstrap/scss/navbar";

.navbar {
  .nav-item {
    margin: 0 ($spacer * 0.5);
    &.active {
      font-weight: 700;
    }

    .nav-link {
      &.dropdown-toggle {
        .label-username {
          display: inline-block;
          position: relative;

          &::before {
            content: attr(data-label);
            font-weight: bold;
            visibility: hidden;
          }
          @include hover-focus {
            font-weight: 700;
          }
        }
        .nav-link-label {
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }

      &:not(.dropdown-toggle),
      &:not(.disabled) {
        position: relative;

        &::before {
          content: attr(data-label);
          font-weight: bold;
          visibility: hidden;
        }

        @include hover-focus {
          font-weight: 700;
        }

        &.active {
          font-weight: 700;
        }
      }
    }
  }
  .nav-link-label {
    text-align: center;
    position: absolute;
    top: $nav-link-padding-y;
    bottom: $nav-link-padding-y;
    right: $navbar-nav-link-padding-x;
    left: $navbar-nav-link-padding-x;
  }

  &.navbar-link-underline {
    .nav-item.active {
      .nav-link {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0.5rem;
          left: 0;
          width: 100%;
          border-radius: $border-radius;
          display: block;
          height: 0.25rem;
          background-color: $seafoam;
        }
      }
    }
  }

  .container-xl,
  .container-fluid {
    padding-left: $spacer;
    padding-right: $spacer;
  }

  .badge {
    &.badge-username {
      color: $white;
      padding: 0;
      font-size: 1rem;
      line-height: calc(2rem - 2px);
      text-align: center;
      border-radius: 2rem;
      margin: -0.5rem 0.4rem -0.5rem 0;
      min-width: 2rem;
      text-transform: uppercase;
      background-color: $navy-dark;
    }
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  margin-right: $spacer;

  &.navbar-logo {
    font-size: initial;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    img {
      height: $spacer * 2;
    }
  }
}

.navbar-toggler {
  border: none;
  margin-right: $spacer;
}

.navbar-light {
  .navbar-toggler {
    color: $navbar-light-active-color;
  }
}

.navbar-dark {
  .navbar-toggler {
    color: $navbar-dark-active-color;
  }
}

// Not sure what this is for
.help-navbar {
  position: relative;
  z-index: 1;
}